import { Button, ButtonType, Modal, Tabs } from 'shared/NovaPrimeUI/legacy-ui';
import { lazy, PropsWithChildren, useState } from 'react';
import classNames from 'classnames';
import useIsSupportUIEnabled from './useIsSupportUIEnabled';
import { SuspenseWithLoader } from 'Routing/SuspenseWrapper';

const UpsertRules = lazy(() => import('./UpsertRules'));
const DocumentDataView = lazy(() => import('./DocumentData'));
const ExportImportRule = lazy(() => import('./ExportImportRules'));
const JSONPathResolver = lazy(() => import('./JSONPathResolver'));

enum RuleManagerTab {
  AddEditRules = 'Add/Edit Rules',
  DocumentData = 'Document Data',
  JSONPathTester = 'Full Mortgage',
  ExportImport = 'Export/Import'
}

const Hidden = ({ isHidden, children }: PropsWithChildren<{ isHidden: boolean }>) => (
  <div className={classNames({ hidden: isHidden })}>{children}</div>
);

const RuleManagerButton = ({ className }: { className?: string }) => {
  const isEnabled = useIsSupportUIEnabled();

  const [activeTab, setActiveTab] = useState<RuleManagerTab>(RuleManagerTab.AddEditRules);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const toggleModal = () => setIsModalOpen((prev) => !prev);

  return (
    <>
      {isEnabled && (
        <Button onClick={toggleModal} className={className}>
          Support UI
        </Button>
      )}

      <Modal
        open={isModalOpen}
        title="Rule Manager"
        width="90%"
        primaryButton={{
          text: 'Close (You will lose the progress)',
          buttonType: ButtonType.danger
        }}
        onClickPrimaryButton={toggleModal}
        className="h-[90vh]"
      >
        <div className="max-h-screen overflow-auto">
          <Tabs
            tabNames={RuleManagerTab}
            selectedTab={activeTab}
            setSelectedTab={setActiveTab}
            padding={'0'}
            tabsPadding={'0 6px'}
          />

          <SuspenseWithLoader>
            <Hidden isHidden={activeTab !== RuleManagerTab.AddEditRules}>
              <UpsertRules />
            </Hidden>
            <Hidden isHidden={activeTab !== RuleManagerTab.DocumentData}>
              <DocumentDataView />
            </Hidden>
            <Hidden isHidden={activeTab !== RuleManagerTab.JSONPathTester}>
              <JSONPathResolver />
            </Hidden>
            <Hidden isHidden={activeTab !== RuleManagerTab.ExportImport}>
              <ExportImportRule />
            </Hidden>
          </SuspenseWithLoader>
        </div>
      </Modal>
    </>
  );
};

export default RuleManagerButton;
