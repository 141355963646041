import styled from 'styled-components';
import { COLORS } from '../../styles/colors';

export const StyledDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 10px 12px;
  background: ${COLORS.N000};
  border-radius: 4px;

  p {
    color: ${COLORS.R700};
  }
  &.info-div {
    border: 1px solid ${COLORS.B500};
  }
  &.success-div {
    border: 1px solid ${COLORS.G500};
  }
  &.error-div {
    border: 1px solid ${COLORS.R500};
  }
  &.warning-div {
    border: 1px solid ${COLORS.O700};
  }
`;
