import { MouseEventHandler, ReactNode, useEffect, useRef } from 'react';
import { CloseSvg } from '../../images';
import Button, { ButtonSize, ButtonType } from '../Button/Button';
import Checkbox from '../Checkbox/Checkbox';
import {
  ActionsContainer,
  CheckboxContainer,
  StyledBackground,
  StyledDialogBox,
  StyledDialogContent,
  StyledHeader,
  StyledSvgContainer
} from './Dialog.styles';

interface Actions {
  text: string;
  className?: string;
  buttonSize?: ButtonSize;
  buttonType?: ButtonType;
  onClickFunction: MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
}
interface CheckboxAction {
  text: string;
  isToggled: boolean;
  onClickFunction: MouseEventHandler<HTMLInputElement>;
}

interface DialogProps {
  open: boolean;
  children: JSX.Element | JSX.Element[];
  className?: string;
  title?: string;
  closeIconEnabled?: boolean;
  escapeKeyEnabled?: boolean;
  clickOutsideCloseEnabled?: boolean;
  closeIconFunction?: any;
  actions?: Actions[];
  checkboxAction?: CheckboxAction;
  maxWidth?: string;
  maxHeight?: string;
  postHeaderContent?: ReactNode;
  headerContent?: ReactNode;
}

const Dialog = ({
  children,
  open,
  title,
  className,
  closeIconEnabled,
  escapeKeyEnabled = true,
  clickOutsideCloseEnabled = true,
  closeIconFunction,
  postHeaderContent,
  actions,
  checkboxAction,
  maxWidth,
  maxHeight,
  headerContent
}: DialogProps) => {
  const handleEscapeKey = (e: KeyboardEvent) => {
    if (escapeKeyEnabled && (e.key === 'Escape' || e.key === 'Esc')) {
      closeIconFunction?.();
    }
  };

  const closeIcon = closeIconEnabled && (
    <StyledSvgContainer onClick={() => closeIconFunction?.()} tabIndex={0}>
      <CloseSvg />
    </StyledSvgContainer>
  );

  const mapedActions =
    actions &&
    actions.map((action) => (
      <Button
        key={action.text}
        text={action.text}
        className={action.className}
        buttonSize={action.buttonSize}
        buttonType={action.buttonType}
        onClick={action.onClickFunction}
        disabled={action.disabled}
      />
    ));

  const checkbox_ = checkboxAction && (
    <CheckboxContainer>
      <Checkbox checked={checkboxAction.isToggled} onClick={checkboxAction.onClickFunction} />
      &nbsp;{checkboxAction.text}
    </CheckboxContainer>
  );

  useEffect(() => {
    if (open) {
      document.addEventListener('keydown', handleEscapeKey);
    }

    return () => {
      document.removeEventListener('keydown', handleEscapeKey);
    };
  }, [open]);

  const modalRef = useRef<HTMLDivElement | null>(null);
  const didMouseDownOriginateInModalBackdrop = useRef(false);

  const outsideClickHandler = (e: any) => {
    if (!clickOutsideCloseEnabled) {
      return;
    }

    if (modalRef.current && !modalRef.current.contains(e.target)) {
      closeIconFunction?.();
    }
  };

  if (!open) {
    return <></>;
  }

  return (
    <StyledBackground
      onClick={(e) => {
        if (didMouseDownOriginateInModalBackdrop.current) {
          // Ignore clicks that originated in the modal content
          outsideClickHandler(e);
        }
      }}
      onMouseDown={(e) => {
        didMouseDownOriginateInModalBackdrop.current = e.target === e.currentTarget;
      }}
    >
      <StyledDialogBox ref={modalRef} maxWidth={maxWidth} maxHeight={maxHeight} className={className}>
        <StyledHeader>
          {headerContent ? headerContent : <h1>{title}</h1>}
          {closeIcon}
        </StyledHeader>
        {postHeaderContent}
        <StyledDialogContent>{children}</StyledDialogContent>
        {actions && (
          <ActionsContainer>
            {checkbox_}
            {mapedActions}
          </ActionsContainer>
        )}
      </StyledDialogBox>
    </StyledBackground>
  );
};

export default Dialog;
