import { CellClassParams, CellStyle } from 'ag-grid-community';
import { getFullDate, isValidTimestamp, utcToDisplayFormat } from './date.helper';

interface HighlightColors {
  even: string;
  odd: string;
}

const isEven = (row: number) => row % 2 === 0;
const formatValue = (value: string) => (isValidTimestamp(value) ? utcToDisplayFormat(value) : value);

export const isValueMatching = (sourceData: Record<string, any>, targetData: Record<string, any>, field: string) => {
  const sourceValue = formatValue(sourceData[field]);
  const targetValue = formatValue(targetData[field]);

  return sourceValue === targetValue;
};

const isFieldValid = (data: Record<string, any>, key: string) => typeof data[key]?.isValid === 'string';
const isFieldToIgnore = (ignoreFields: string[] | undefined, field: string) =>
  ignoreFields && ignoreFields?.includes(field);

const createCombinedSearchFieldText = (data: any, combinedSearchFields: string[][], length: number) => {
  let valuesToCheck = '';

  for (let rowIdx = 0; rowIdx < length; rowIdx++) {
    const row = combinedSearchFields[rowIdx];

    for (let colIndex = 0; colIndex < row.length; colIndex++) {
      const searchField = row[colIndex];
      let dataValue = data[searchField];

      if (dataValue && isValidTimestamp(dataValue)) {
        dataValue = getFullDate(dataValue);
      }

      valuesToCheck += ` ${dataValue}`;
    }
  }
  return valuesToCheck;
};
const isSearchTextWithinCol = (value: string, filterText: string) =>
  String(value)?.includes(filterText) || String(value).toLowerCase().includes(filterText.toLowerCase());

export function getHighlightCellStyle(
  filterText: string,
  highlightColors?: HighlightColors,
  targetData?: any,
  fieldsToIgnore?: string[],
  combinedSearchFields?: string[][]
): (params: any) => CellStyle {
  return function (params: CellClassParams): CellStyle {
    const rowData = params.data || {};
    const columnField = params.colDef.field || '';

    if (filterText.length) {
      const value = formatValue(params.value);
      const rowBackgroundColor = { backgroundColor: '#e5fff8' };

      const csfLength = combinedSearchFields?.length;

      if (csfLength) {
        const { data } = params;
        const valuesToCheck = createCombinedSearchFieldText(data, combinedSearchFields, csfLength);

        if (isSearchTextWithinCol(valuesToCheck, filterText)) {
          return rowBackgroundColor;
        }
      }

      if (isSearchTextWithinCol(value, filterText)) {
        return rowBackgroundColor;
      }
    }

    if (highlightColors && isFieldValid(params.data.data, columnField)) {
      const color = isEven(params.node.rowIndex!) ? highlightColors.even : highlightColors.odd;

      return { backgroundColor: color };
    }

    if (
      targetData &&
      !isValueMatching(rowData, targetData, columnField) &&
      !isFieldToIgnore(fieldsToIgnore, columnField)
    ) {
      return { backgroundColor: '#f5222d40', borderRadius: '4px' };
    }
    return {};
  };
}
