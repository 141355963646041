const isBlob = (data: any) => typeof data === 'string' && data.search('blob:') !== -1;
const isPNG = (data: any) => typeof data === 'string' && data.charAt(0) === 'i';
const isJPEG = (data: any) => typeof data === 'string' && data.charAt(0) === '/';

export function formatImageData(data: File | string | undefined) {
  if (!data) return data;

  if (data instanceof File) {
    return window.URL.createObjectURL(data);
  }

  if (isBlob(data)) {
    return data;
  }

  if (isPNG(data)) {
    return `data:png;base64,${data}`;
  }

  if (isJPEG(data)) {
    return `data:jpeg;base64,${data}`;
  }

  return data;
}
