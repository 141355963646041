import styled from 'styled-components';
import { mq } from '../../styles/breakpoints';
import { COLORS } from '../../styles/colors';

export const StyledHeaderBackground = styled.header`
  color: ${COLORS.N500};
  background-color: ${COLORS.N000};
  position: sticky;
  top: 0;
  right: 0;
  left: 0;
  z-index: 30;
  padding: 16px 20px;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 0.05);
  display: flex;
  align-items: center;
  justify-content: space-between;

  ${mq[1]} {
    // 480px
    padding: 16px 40px;
  }
  ${mq[4]} {
    // 1280px
    padding: 16px 96px;
  }
`;

export const StyledLimit = styled.div`
  display: flex;
  width: 100%;
  margin: 0 auto;
  justify-content: space-between;
`;

export const StyledLogoAndLinksGroup = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledBurgerMenuButton = styled.button`
  padding: 13px;
  cursor: pointer;
  border-radius: 4px;
  color: ${COLORS.N500};
  display: flex;
  :hover {
    outline: 1px solid ${COLORS.N800};
  }
  :active {
    outline: 2px solid ${COLORS.N800};
  }
  ${mq[2]} {
    // 648px
    display: none;
  }
`;

export const StyledLogo = styled.div`
  height: 12px;
  cursor: pointer;
  color: ${COLORS.N600};
`;

export const StyledNavContainer = styled.nav`
  display: flex;
  align-items: center;
  margin-left: 20px;
  display: none;
  .nav-link {
    line-height: 18px;
    font-size: 14px;
    font-weight: 600;
    margin: 15px 10px;
    color: ${COLORS.N400};
    &.active {
      color: ${COLORS.N800};
    }
  }

  ${mq[2]} {
    // 648px
    display: flex;
  }
`;

export const StyledDropDownMenu = styled.nav`
  padding: 30px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  z-index: 29;
  top: 78px;
  left: 0;
  right: 0;
  width: 100%;
  height: fit-content;
  background-color: ${COLORS.N000};
  box-shadow: 0px 25px 15px 0px rgba(0, 0, 0, 0.05);
  gap: 10px;
  .nav-link {
    line-height: 18px;
    font-size: 14px;
    font-weight: 600;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 48px;
    width: 100%;
    color: ${COLORS.N400};
    &.active {
      color: ${COLORS.N800};
    }
  }

  ${mq[2]} {
    // 648px
    display: none;
  }
`;
