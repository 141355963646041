import { RootState } from '../../store';

export const selectQualityControlSearchValue = (state: RootState) => state.qualityControl.searchValue;
export const selectQualityControlCompletedLoansFilter = (state: RootState) => state.qualityControl.completedLoansFilter;
export const selectQualityControlRecentlyViewedChecklists = (state: RootState) =>
  state.qualityControl.recentlyViewedChecklists;
export const selectQualityControlAllLoansGridState = (state: RootState) => state.qualityControl.allLoansGridState;
export const selectQualityControlLoanNumbersFilterValue = (state: RootState) =>
  state.qualityControl.loanNumbersFilterValue;
export const selectQualityControlPdfThumbnailToggle = (state: RootState) => state.qualityControl.pdfThumbnailToggle;
export const selectQualityControlPdfZoom = (state: RootState) => state.qualityControl.pdfZoom;
export const selectQualityControlIsChecklistFoldersExpanded = (state: RootState) =>
  state.qualityControl.isChecklistFoldersExpanded;
export const selectOrganizationalTags = (state: RootState) => state.qualityControl.organizationalTags;
