import { Dispatch, createListenerMiddleware } from '@reduxjs/toolkit';
import { RootState } from '../../store';
import { _closeToast, _openToast, _removePreviousToastFromQueue, _setTimerId, addToast } from './toast.slice';
import { openMortgages } from '../mortgage/mortgage.slice';
import { ToastStatus } from 'shared/NovaPrimeUI/legacy-ui';
import { selectMarketplaceV2FeatureFlag } from '../config/config.selectors';
import OpenLoansSuccessToast from 'shared/components/MortgageFlow/OpenLoansSuccessToast';
import { selectFilteredDraftMortgages } from '../mortgage/mortgage.selectors';

const TIME_BEFORE_SHOWING_NEXT_TOAST = 100;
const TIME_BEFORE_CLOSING_TOAST = 5000;

// open toast; set a timeout to close the notification; store the timeoutId
export function _openToastAndInitializeTimer(dispatch: Dispatch) {
  dispatch(_openToast());

  const timerId = window.setTimeout(() => {
    dispatch(_closeToast());
  }, TIME_BEFORE_CLOSING_TOAST);

  dispatch(_setTimerId(timerId));
}

const toastListener = createListenerMiddleware();

// _closeToast(), listener will run after user manually closes toast, or timeout duration is over
toastListener.startListening({
  actionCreator: _closeToast,
  effect: (action, api) => {
    const { toast } = api.getState() as RootState;

    // cleanup the timer in case the user manually dismissed toast before timeout
    if (toast.timerId) {
      window.clearTimeout(toast.timerId);
      api.dispatch(_setTimerId(null));
    }

    // remove the previous notification from the queue
    api.dispatch(_removePreviousToastFromQueue());

    // if there are more notifications in the queue, show the next one after a delay
    if (toast.queue.length - 1 > 0) {
      window.setTimeout(() => {
        // set timer to close the next notification
        _openToastAndInitializeTimer(api.dispatch);
      }, TIME_BEFORE_SHOWING_NEXT_TOAST);
    }
  }
});

toastListener.startListening({
  actionCreator: openMortgages.fulfilled,
  effect: (action, api) => {
    const { meta } = action;
    const state = api.getState() as RootState;
    const isMarketplaceV2 = selectMarketplaceV2FeatureFlag(state);
    const filteredDraftMortgages = selectFilteredDraftMortgages(state);

    if (isMarketplaceV2) {
      api.dispatch(
        addToast({
          status: ToastStatus.success,
          text: <OpenLoansSuccessToast numLoansOpened={meta.arg.length} numDraftLoans={filteredDraftMortgages.length} />
        })
      );
    } else {
      api.dispatch(
        addToast({
          status: ToastStatus.success,
          text: `${meta.arg.length} Mortgage(s) made available for sale and have moved to the Ready for Sale tab.`
        })
      );
    }
  }
});

export default toastListener;
