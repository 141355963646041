import { Button } from 'shared/NovaPrimeUI/legacy-ui';
import { generatePath, useNavigate } from 'react-router-dom';
import { MortgageRoutes } from 'Routing/routeStrings';
import { useAppDispatch, useAppSelector } from 'state-management/hooks';
import { selectSelectedBidTapeId } from 'state-management/slices/bid-tape/bid-tape.selectors';
import { _closeToast } from 'state-management/slices/toast/toast.slice';
import { useCallback } from 'react';

interface OpenLoansSuccessToastProps {
  numLoansOpened: number;
  numDraftLoans: number;
}

const OpenLoansSuccessToast = ({ numLoansOpened, numDraftLoans }: OpenLoansSuccessToastProps) => {
  const dispatch = useAppDispatch();
  const selectedBidTapeId = useAppSelector(selectSelectedBidTapeId);
  const navigate = useNavigate();
  const onActionButtonClick = useCallback(() => {
    // Include the selected bid tape template filter in the next URL
    const nextUrl = generatePath(MortgageRoutes.routePath, {
      mortgageUrlSlug: MortgageRoutes.open.path,
      bidTapeId: selectedBidTapeId
    });

    // Next URL uses navigation state
    navigate(nextUrl, { state: { openLoansSuccessToastActionClicked: true } });
    dispatch(_closeToast());
  }, [dispatch, navigate, selectedBidTapeId]);

  return (
    <div className="action-toast-content">
      <span>
        <span className="text-semibold">{numLoansOpened}</span>/{numDraftLoans} Mortgages made available for sale
      </span>
      <Button className="action-toast-button" onClick={onActionButtonClick}>
        Create Bid Tape in Ready for Sale
      </Button>
    </div>
  );
};

export default OpenLoansSuccessToast;
