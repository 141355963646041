import { StyledButton, StyledSpan, StyledTabs, TabsCol, TextWrap } from './Tabs.styles';
import { Dispatch, ReactNode, SetStateAction, useRef } from 'react';

export type TabIconPosition = 'left' | 'right';

interface TabsProps<T> {
  tabNames: T;
  tabIcons?: ReactNode[];
  tabIconsPositions?: TabIconPosition[];
  selectedTab: any;
  setSelectedTab: Dispatch<SetStateAction<any>>;
  //TODO: fix typing
  callback?: any;
  spacing?: string;
  className?: string;
  marginBottom?: string;
  padding?: string;
  tabsPadding?: string;
  tabsRefs?: any;
}

const Tabs = <T,>({
  tabNames,
  tabIcons,
  tabIconsPositions,
  selectedTab,
  setSelectedTab,
  callback,
  className,
  tabsRefs,
  marginBottom,
  padding,
  tabsPadding
}: TabsProps<T>): JSX.Element => {
  const onClickTab = (tab: any) => {
    setSelectedTab(tab);
    if (callback) {
      callback();
    }
  };

  const tabs = (
    <StyledTabs data-testid="Tabs" className={className} marginBottom={marginBottom} padding={padding}>
      {Object.values(tabNames as any).map((value: any, i: number) => {
        return (
          <TabsCol
            key={`tab-${value}`}
            onClick={() => onClickTab(value)}
            className={`${selectedTab === value && 'active'}`}
            ref={
              tabsRefs
                ? (ref) => {
                    tabsRefs[i] = ref;
                  }
                : useRef(null)
            }
          >
            <TextWrap className={tabIconsPositions?.[i] === 'right' ? 'reverse' : undefined}>
              {tabIcons && tabIcons[i] && typeof tabIcons[i] !== 'string' && tabIcons[i]}
              <StyledButton padding={tabsPadding} tabIndex={0}>
                {value}
              </StyledButton>
            </TextWrap>

            <StyledSpan className={`${selectedTab === value && 'active'}`} />
          </TabsCol>
        );
      })}
    </StyledTabs>
  );

  return tabs;
};

export default Tabs;
