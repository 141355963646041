import styled from 'styled-components';
import { Active, Dash } from '../../images';

export const StyledIconActive = styled(Active)`
  width: 15px;
  height: 15px;
`;
export const StyledIconDash = styled(Dash)`
  width: 15px;
  height: 15px;
`;

export const StyledLabel = styled.label`
  box-sizing: border-box;
  width: 15px;
  height: 15px;
  cursor: pointer;

  &.custom-checkbox .checkmark {
    width: 15px;
    height: 15px;
    border: 1.5px solid #ccd1de;

    display: inline-block;
    border-radius: 4px;
  }

  &.custom-checkbox input:checked + .checkmark {
    border: none;
  }

  &.custom-checkbox input {
    display: none;
  }
`;
