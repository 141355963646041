import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IMortgage } from 'shared/models/Mortgage';
import { GridState } from 'ag-grid-community';
import { IDocumentTag } from 'shared/models/Analysis-Document';

export type TGridStateWithoutRowSelection = Omit<GridState, 'rowSelection'>;

export interface QualityControlSlice {
  searchValue: string;
  completedLoansFilter: boolean;
  recentlyViewedChecklists: Array<Pick<IMortgage, 'internalId' | 'loanNumber'>>;
  allLoansGridState: TGridStateWithoutRowSelection;
  loanNumbersFilterValue: string | null;
  pdfThumbnailToggle: boolean;
  pdfZoom: number;
  isChecklistFoldersExpanded: boolean;
  organizationalTags: IDocumentTag['id'][];
}
export const LIMIT_RECENTLY_VIEWED_ITEMS = 10;

export const QualityControlInitialState: QualityControlSlice = {
  searchValue: '',
  completedLoansFilter: true,
  recentlyViewedChecklists: [],
  allLoansGridState: {},
  loanNumbersFilterValue: '',
  pdfThumbnailToggle: true,
  pdfZoom: 1,
  isChecklistFoldersExpanded: false,
  organizationalTags: []
};

const qualityControlSlice = createSlice({
  name: 'qualityControl',
  initialState: QualityControlInitialState,
  reducers: {
    setSearchValue: (state, action: PayloadAction<QualityControlSlice['searchValue']>) => {
      state.searchValue = action.payload;
    },
    setCompletedLoansFilter: (state, action: PayloadAction<QualityControlSlice['completedLoansFilter']>) => {
      state.completedLoansFilter = action.payload;
    },
    setRecentlyViewedChecklists: (state, action: PayloadAction<Pick<IMortgage, 'internalId' | 'loanNumber'>>) => {
      const { internalId, loanNumber } = action.payload;

      if (!internalId || !loanNumber) {
        return;
      }

      const update = [...state.recentlyViewedChecklists];
      const loanIndex = update.findIndex((loan) => loan.internalId === internalId);

      // remove if exists
      if (loanIndex !== -1) {
        update.splice(loanIndex, 1);
      }

      // add to front
      update.unshift({ internalId, loanNumber });

      // take first X items
      if (update.length > LIMIT_RECENTLY_VIEWED_ITEMS) {
        update.splice(LIMIT_RECENTLY_VIEWED_ITEMS);
      }

      state.recentlyViewedChecklists = update;
    },
    setAllLoansGridState: (state, action: PayloadAction<TGridStateWithoutRowSelection>) => {
      state.allLoansGridState = action.payload;
    },
    setLoanNumbersFilterValue: (state, action: PayloadAction<QualityControlSlice['loanNumbersFilterValue']>) => {
      state.loanNumbersFilterValue = action.payload;
    },
    setPdfThumbnailToggle: (state, action: PayloadAction<QualityControlSlice['pdfThumbnailToggle']>) => {
      state.pdfThumbnailToggle = action.payload;
    },
    setPdfZoom: (state, action: PayloadAction<QualityControlSlice['pdfZoom']>) => {
      state.pdfZoom = action.payload;
    },
    setIsChecklistFoldersExpanded: (
      state,
      action: PayloadAction<QualityControlSlice['isChecklistFoldersExpanded']>
    ) => {
      state.isChecklistFoldersExpanded = action.payload;
    },
    setOrganizationalTags: (state, action: PayloadAction<QualityControlSlice['organizationalTags']>) => {
      state.organizationalTags = action.payload;
    }
  }
});

// Whenever we add something to the slice state, we need to increment the version number and ensure the restore function can handle it
export const qualityControlVersion = '1.0.2';
export const PERSISTED_QUALITY_CONTROL_STORAGE_KEY = 'QUALITY_CONTROL';
export const {
  setSearchValue,
  setCompletedLoansFilter,
  setRecentlyViewedChecklists,
  setAllLoansGridState,
  setLoanNumbersFilterValue,
  setPdfThumbnailToggle,
  setPdfZoom,
  setIsChecklistFoldersExpanded,
  setOrganizationalTags
} = qualityControlSlice.actions;
export const QUALITY_CONTROL_ACTIONS_TO_TRIGGER_PERSIST = [
  setRecentlyViewedChecklists,
  setCompletedLoansFilter,
  setAllLoansGridState,
  setLoanNumbersFilterValue,
  setPdfThumbnailToggle,
  setPdfZoom,
  setIsChecklistFoldersExpanded,
  setOrganizationalTags
];

export default qualityControlSlice.reducer;
