import { User } from '../../images';
import { formatImageData } from '../../images/shared';
import DropDown from '../DropDown/DropDown';
import ProfileImage from '../ProfileImage/ProfileImage';

interface LoginStatusProps {
  auth: any;
  useAppSelector: any;
  handleLogout: any;
}

interface ButtonContentProps {
  useAppSelector: any;
}

const ButtonContent = (props: ButtonContentProps) => {
  const { currentUser } = props.useAppSelector((state: any) => state.access) || { currentUser: '' };
  const isObjectEmpty = (obj: Record<string, unknown>) => obj && Object.keys(obj).length === 0;

  const initials = (currentUser?.firstName || '').substring(0, 1) + (currentUser?.lastName || '').substring(0, 1);

  return (
    <>
      {isObjectEmpty(currentUser) || !currentUser?.image ? (
        <User
          width={42}
          height={42}
          className="rounded-full bg-slate-200 stroke-slate-600 stroke-0 p-2.5"
          preserveAspectRatio="xMidYMid meet"
          viewBox="0 0 20 20"
        />
      ) : (
        <ProfileImage initials={initials} image={formatImageData(currentUser.image)} />
      )}
    </>
  );
};

const ShowLoginStatus = (props: LoginStatusProps): JSX.Element => {
  const { auth, useAppSelector, handleLogout } = props;

  const dropDownContent = [
    {
      text: auth.user?.nickname,
      subTxt: auth.user?.email,
      location: 'noloc'
    },
    {
      text: 'Sign Out',
      className: 'showloginstatus-dropdown-sign-out',
      handleOnClick: handleLogout
    }
  ];

  const buttonContent = <ButtonContent useAppSelector={useAppSelector} />;

  return (
    <>
      {auth.isAuthenticated ? (
        <DropDown dropDownContent={dropDownContent} buttonContent={buttonContent} />
      ) : (
        <button onClick={() => auth.signinRedirect({ state: { url: location.href } })}>Login</button>
      )}
    </>
  );
};

export default ShowLoginStatus;
