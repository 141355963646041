import { createSelector } from 'reselect';
import { RootState } from '../../store';

export const selectValidations = (state: RootState) => state.statuses.validations;

export const makeSelectValidationsByMortgageId = () =>
  createSelector(
    selectValidations,
    (_: RootState, mortgageId: string) => mortgageId,
    (validations, mortgageId) => validations[mortgageId]
  );
