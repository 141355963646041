import styled from 'styled-components';
import { COLORS } from '../../styles/colors';

export const StyledDiv = styled.div<{ labelTextColor?: string }>`
  position: relative;
  display: flex;
  flex-direction: column;

  input {
    font-size: 14px;
    font-weight: 600;
    padding: 15px 20px 15px 16px;
    display: block;
    background: ${COLORS.N000};
    outline: 1px solid ${COLORS.N300};
    color: ${COLORS.N800};
    border-radius: 13px;
    &:focus-within {
      outline: 2px solid ${COLORS.N800};
    }

    &.error {
      outline: 2px solid ${COLORS.R500};
    }
  }

  &:focus-within label {
    transform: translate(0, -22px) scale(0.8);
    font-size: 12.5px;
    line-height: 13px;
    padding: 0 3px;
  }

  .filled {
    transform: translate(0, -22px) scale(0.8);
    font-size: 12.5px;
    line-height: 13px;
    padding: 0 3px;
  }

  label {
    top: 15px;
    color: ${COLORS.N400};
    background-color: white;
    position: absolute;
    pointer-events: none;
    transform-origin: left;
    transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
    font-size: 14px;
    font-weight: 600;
    left: 16px;
    &.error {
      color: ${COLORS.R500};
    }
  }
`;

export const ErrorMessage = styled.p`
  position: absolute;
  bottom: -18px;
  left: 16px;
  font-size: 10px;
  font-weight: 600;
  color: ${COLORS.R500};
  line-height: 13px;
`;
