import { createSlice } from '@reduxjs/toolkit';
import { BrowserOptions } from '@sentry/react';

interface OAuthConfig {
  authority: string;
  clientId: string;
  audience: string;
  cookieDomain: string;
}
export type TCompanyConfig = { name: string };
type TMixpanelConfig = { token: string };
type TFeatureFlag =
  | 'showQualityV2'
  | 'allowSingleGroupFirms'
  | 'aot'
  | 'showAutoPassMode'
  | 'showPDFEdit'
  | 'showShipping'
  | 'marketplaceLqaStatus'
  | 'marketplaceV2';
export interface Config {
  company: string;
  freddieMacId: string;
  fannieMaeId: string;
  pennyMacId: string;
  oauth: OAuthConfig;
  backendUrl: string;
  webSocketUrl: string;
  agGridLicenseKey: string;
  redirectUrl?: string;
  companies: Record<string, TCompanyConfig>;
  sentry?: BrowserOptions;
  mixpanel?: TMixpanelConfig;
  featureFlags?: Partial<Record<TFeatureFlag, boolean>>;
}

const _configPromise = fetch(`/config/${location.hostname.split('.')[0]}.json`).then(
  (response) => response.json() as Promise<Config>
);

const configSlice = createSlice({
  name: 'config',
  initialState: {} as Config,
  reducers: {
    initConfig: (_, action) => action.payload
  }
});

export const { initConfig } = configSlice.actions;
export const getGlobalConfig = () => _configPromise;
export default configSlice.reducer;
