import { CloseSvg, CircleCheckSvg } from '../../images';
import { StyledSelectorBar, StyledSelectorBarDiv } from './SelectorBar.styles';

interface SelectorBarProps {
  buttonText: string;
  amountSelected: number;
  clearSelected: () => void;
  onClick: () => void;
  icon?: React.ReactNode;
}

const SelectorBar = ({ buttonText, amountSelected, clearSelected, icon, onClick }: SelectorBarProps) => {
  return (
    <StyledSelectorBar>
      <p>{amountSelected} Selected</p>

      <StyledSelectorBarDiv onClick={onClick}>
        {icon || <CircleCheckSvg width={20} height={20} />}
        <p>{buttonText}</p>
      </StyledSelectorBarDiv>

      <CloseSvg width={18} height={18} className="cursor-pointer" onClick={clearSelected} />
    </StyledSelectorBar>
  );
};

export default SelectorBar;
