import styled from 'styled-components';
import { COLORS } from '../../styles/colors';

export const StyledProfileImage = styled.div`
  .overlay-base {
    position: absolute;
    bottom: 0;
    border-radius: 50% 50%;
    transition: 0.5s ease;
    display: flex;
    justify-content: center;
    text-align: center;
    flex-direction: column;
    opacity: 0;
    cursor: pointer;
    color: ${COLORS.N000};
    background-color: ${COLORS.N800};
  }

  &.container-lg {
    position: relative;
    width: 80px;
    min-width: 80px;

    .overlay-lg {
      width: 80px;
      height: 80px;
      font-size: 20px;
    }

    &:hover .overlay-lg {
      opacity: 1;
    }
  }

  &.container {
    position: relative;
    width: 44px;
    min-width: 44px;

    .overlay {
      width: 44px;
      height: 44px;
      font-size: 10px;
    }

    &:hover .overlay {
      opacity: 1;
    }
  }

  &.container-sm {
    position: relative;
    width: 32px;
    min-width: 32px;

    .overlay-sm {
      width: 32px;
      height: 32px;
      font-size: 6px;
    }

    &:hover .overlay {
      opacity: 1;
    }
  }
`;

export const StyledAvatar = styled.span`
  .circular-image {
    width: 44px;
    height: 44px;
    min-height: 44px;
    min-width: 44px;
    border-radius: 50% 50%;
    font-size: 14px;
    &.image-size-sm {
      width: 32px;
      height: 32px;
      min-height: 32px;
      min-width: 32px;
      font-size: 14px;
    }
    &.image-size-lg {
      width: 80px;
      height: 80px;
      min-height: 80px;
      min-width: 80px;
      font-size: 26px;
    }
    color: ${(props) => props.theme.tooltipFont};
    background-color: ${(props) => props.theme.imagePlaceholder};
    &.profile-image-primary {
      background-color: ${(props) => props.theme.profileImagePrimary};
    }
    &.profile-image-secondary {
      background-color: ${(props) => props.theme.profileImageSecondary};
    }
    &.profile-image-warning {
      background-color: ${(props) => props.theme.profileImageWarning};
    }
    &.profile-image-danger {
      background-color: ${(props) => props.theme.profileImageDanger};
    }
    &.profile-image-info {
      background-color: ${(props) => props.theme.profileImageInfo};
    }
    &.profile-image-red-light {
      background-color: ${(props) => props.theme.profileImageRedLight};
    }
    &.profile-image-red-dark {
      background-color: ${(props) => props.theme.profileImageRedDark};
    }
    &.profile-image-orange-light {
      background-color: ${(props) => props.theme.profileImageOrangeLight};
    }
    &.profile-image-orange-dark {
      background-color: ${(props) => props.theme.profileImageOrangeDark};
    }
    &.profile-image-gold-light {
      background-color: ${(props) => props.theme.profileImageGoldLight};
    }
    &.profile-image-gold-dark {
      background-color: ${(props) => props.theme.profileImageGoldDark};
    }
    &.profile-image-green-light {
      background-color: ${(props) => props.theme.profileImageGreenLight};
    }
    &.profile-image-green-dark {
      background-color: ${(props) => props.theme.profileImageGreenDark};
    }
    &.profile-image-cyan-light {
      background-color: ${(props) => props.theme.profileImageCyanLight};
    }
    &.profile-image-cyan-dark {
      background-color: ${(props) => props.theme.profileImageCyanDark};
    }
    &.profile-image-aqua-light {
      background-color: ${(props) => props.theme.profileImageAquaLight};
    }
    &.profile-image-aqua-dark {
      background-color: ${(props) => props.theme.profileImageAquaDark};
    }
    &.profile-image-persian-light {
      background-color: ${(props) => props.theme.profileImagePersianLight};
    }
    &.profile-image-persian-dark {
      background-color: ${(props) => props.theme.profileImagePersianDark};
    }
    &.profile-image-purple-light {
      background-color: ${(props) => props.theme.profileImagePurpleLight};
    }
    &.profile-image-purple-dark {
      background-color: ${(props) => props.theme.profileImagePurpleDark};
    }
    &.profile-image-magenta-light {
      background-color: ${(props) => props.theme.profileImageMagentaLight};
    }
    &.profile-image-magenta-dark {
      background-color: ${(props) => props.theme.profileImageMagentaDark};
    }
    &.profile-image-volcano-light {
      background-color: ${(props) => props.theme.profileImageVolcanoLight};
    }
    &.profile-image-volcano-dark {
      background-color: ${(props) => props.theme.profileImageVolcanoDark};
    }
    &.profile-image-black-light {
      background-color: ${(props) => props.theme.profileImageBlackLight};
    }
    &.profile-image-black-dark {
      background-color: ${(props) => props.theme.profileImageBlackDark};
    }
  }
`;
