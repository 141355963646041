import styled from 'styled-components';
import { mq } from '../../styles/breakpoints';
import { COLORS } from '../../styles/colors';

export const StyledButton = styled.button<{ isHighlight: boolean }>`
  text-align: center;
  text-decoration: none;
  display: flex;
  border-radius: 11px;
  max-height: 42px;
  justify-content: center;
  &.button-none {
    padding: 0px;
  }
  &.button-compact {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    padding: 2px 13px;
  }
  &.button-default {
    font-size: 14px;
    font-weight: 400;
    line-height: 22px;
    padding: 5px 14px;
    border-radius: 4px;
  }
  &.button-large {
    font-size: 16px;
    font-weight: 700;
    line-height: 24px;
    padding: 8px 14px;
  }
  &.button-primary {
    color: ${COLORS.N000};
    background: ${COLORS.N700};
    padding: 10px 20px;
    border-radius: 13px;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    &:hover {
      background: ${COLORS.N900};
    }
    &.disabled {
      background: ${COLORS.N300};
      color: ${COLORS.N000};
    }
  }

  &.button-secondary {
    background: ${COLORS.N000};
    color: ${COLORS.N500};
    border: 1px solid ${COLORS.N300};
    border-radius: 13px;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    padding: 10px 20px;
    &:hover {
      border-color: ${COLORS.N800};
    }
    &.disabled {
      color: ${COLORS.N300};
      &:hover {
        border-color: ${COLORS.N300};
      }
    }
    .button-spinner {
      border-color: var(--color-n700) transparent var(--color-n700) var(--color-n700);
    }
  }
  &.button-heading {
    background: ${COLORS.N000};
    color: ${COLORS.N500};
    height: fit-content;
    width: fit-content;
    border-radius: 13px;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    padding: 10px 16px;
    border: ${(props) => (props.isHighlight ? `1px solid ${COLORS.N800}` : `1px solid ${COLORS.N300}`)};
    box-shadow: ${(props) => (props.isHighlight ? `0 0 0 1px ${COLORS.N800}` : 'none')};
    &:hover:enabled {
      border-color: ${COLORS.N800};
    }
    &:enabled:active {
      border: 1px solid ${COLORS.N800};
      box-shadow: 0 0 0 1px ${COLORS.N800};
      outline: none;
    }
    &:disabled {
      color: ${COLORS.N300};
    }
    .button-spinner {
      border-color: var(--color-n700) transparent var(--color-n700) var(--color-n700);
    }
  }
  &.button-danger {
    background: ${COLORS.R500};
    color: ${COLORS.N000};
    padding: 10px 20px;
    gap: 10px;
    border-radius: 13px;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    &:hover {
      background: ${COLORS.R550};
    }
  }
  &.button-success {
    background: ${COLORS.G700};
    color: ${COLORS.N000};
    padding: 10px 20px;
    gap: 10px;
    border-radius: 13px;
    font-family: 'Source Sans Pro';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
    &:hover {
      background: ${COLORS.G750};
    }
  }
`;

export const StyledButtonRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;
export const HiddenText = styled.span<{ sizeSensitive?: boolean }>`
  display: ${(props) => (props.sizeSensitive ? 'none' : 'flex')};
  ${mq[5]} {
    // 1440px
    display: flex;
  }
`;

export const ButtonSpinner = styled.div`
  border: 0.25em solid var(--color-n000);
  border-right-color: transparent;
  border-radius: 9999px;
  aspect-ratio: 1 / 1;
  height: 1.4em;
  position: absolute;
  animation: spin 500ms linear infinite;
  transform-origin: center;

  @keyframes spin {
    to {
      transform: rotate(360deg);
    }
  }
`;
