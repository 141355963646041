import { IDocumentTag, IRulesSummaryDocumentAPI } from 'shared/models/Analysis-Document';
import { IMortgageFlat } from 'shared/models/Mortgage';

export interface QualityFeature {
  errors: number;
  isLoading: boolean;
  isPending: boolean;
}

export interface IAllLoansTableData
  extends Pick<
    IMortgageFlat,
    | 'internalId'
    | 'plannedClosingDate'
    | 'status'
    | 'type'
    | 'loanNumber'
    | 'loanProgram'
    | 'automatedUnderwritingSystemType'
    | 'propertyType'
  > {
  closeChecklist: QualityFeature;
  documentCategorization: QualityFeature;
  closeDate?: IMortgageFlat['closedDate'] | null;
  complete?: boolean;
}

// TODO improve checklist table types HD-7509
export interface IChecklistReviewTableBaseData {
  docId: IRulesSummaryDocumentAPI['documentId'];
  // TODO consider consolidating missingDocument/isProcessing/rulesToReview to 1 type, status
  missingDocument: boolean;
  isProcessing: boolean;
  isDocumentError: boolean;
  categoryCount: number;
  preferredDocument?: boolean;
  tags?: IDocumentTag['displayName'][];
  uploadDate?: string;
  archiveReason?: string;
}

export interface IChecklistReviewTableDetailData extends IChecklistReviewTableBaseData {
  label: string;
  parties: IRulesSummaryDocumentAPI['parties'];
}

export interface IChecklistReviewTableData extends IChecklistReviewTableBaseData {
  category: string;
  categoryCountToComplete: number;
  childItems: IChecklistReviewTableDetailData[];
  flatChildItems: IChecklistReviewTableDetailData[];
  archivedDocuments: IChecklistReviewTableDetailData[];
  documentsGroupedByBorrower: Record<string, IChecklistReviewTableDetailData[]>;
  hasMultiDocs?: boolean;
}

export enum RuleSections {
  CORRECTIONS_NEEDED = 'Verifications Needed',
  COMPLETED = 'Completed',
  UPDATES_PENDING = 'Updates Pending',
  VERIFIY_MATCHES = 'Verify Matches'
}
