import { AsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { IBaseThunk, thunkReactQueryMiddleware } from '../thunks/base.thunks';
import { IDeliveryDateRepository } from 'shared/api/interfaces/IDeliveryDateRepository';
import { IDeliveryDate } from 'shared/models/DeliveryDate';
import { DELIVERY_DATE_QUERY_KEY } from 'state-management/slices/deliveryDate/hooks/useDeliveryDateQuery';

export class DeliveryDateThunks implements IBaseThunk<IDeliveryDate> {
  private readonly deliveryDateRepository!: IDeliveryDateRepository;

  private baseIdentifier = 'deliveryDate';

  constructor(_deliveryDateRepository: IDeliveryDateRepository) {
    this.deliveryDateRepository = _deliveryDateRepository;

    if (this.deliveryDateRepository === null) {
      throw new Error('deliveryDateRepository has not been instantiated!');
    }
  }

  get() {
    const action = `${this.baseIdentifier}/get`;

    return thunkReactQueryMiddleware<IDeliveryDate, void>(action, () => ({
      queryKey: DELIVERY_DATE_QUERY_KEY,
      queryFn: this.deliveryDateRepository.get
    }));
  }

  updateDeliveryDate() {
    const action = `${this.baseIdentifier}/update`;

    return thunkReactQueryMiddleware<IDeliveryDate, IDeliveryDate>(action, (deliveryDate: IDeliveryDate) => ({
      retry: false,
      queryFn: () => this.deliveryDateRepository.updateDeliveryDate(deliveryDate)
    }));
  }

  add(): AsyncThunk<AxiosResponse<IDeliveryDate, any>, any, Record<string, unknown>> {
    throw new Error('Method not implemented.');
  }

  update(): AsyncThunk<
    AxiosResponse<IDeliveryDate, any>,
    { id: string; item: IDeliveryDate },
    Record<string, unknown>
  > {
    throw new Error('Method not implemented.');
  }

  delete(): AsyncThunk<AxiosResponse<IDeliveryDate, any>, string, Record<string, unknown>> {
    throw new Error('Method not implemented.');
  }

  getAll(): AsyncThunk<AxiosResponse<IDeliveryDate[], any>, void, Record<string, unknown>> {
    throw new Error('Method not implemented.');
  }
}
