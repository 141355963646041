import styled from 'styled-components';
import { COLORS } from '../../styles/colors';

export const DropdownInputContainer = styled.div`
  padding: 15px 20px 15px 16px;
  color: ${COLORS.N800};
  background: ${COLORS.N000};
  outline: 1px solid ${COLORS.N300};
  border-radius: 13px;
  position: relative;
  display: flex;
  height: 50px;
  cursor: pointer;
  align-items: center;
  justify-content: space-between;
  &.selected {
    outline: 2px solid ${COLORS.N800};
  }
  &.error {
    color: ${COLORS.R500};
    outline: 2px solid ${COLORS.R500};
  }
  .cheveron {
    color: ${COLORS.N500};
    cursor: pointer;
    min-width: 25px;
  }
  .dropdown_position {
    top: 60px;
    left: 0px;
    z-index: 1;
    position: absolute;
    max-height: 200px;
    width: 100%;
    text-transform: capitalize;
    &.error {
      outline: 2px solid ${COLORS.R500};
    }
  }
`;

export const DropdownInputLabel = styled.div`
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  font-weight: 600;
  position: absolute;
  background-color: ${COLORS.N000};
  transition: 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;
  color: ${COLORS.N400};
  transform-origin: left;
  &.filled {
    transform: translate(0, -26px) scale(0.8);
    font-size: 12.5px;
    line-height: 13px;
    padding: 0 3px;
  }
  &.error {
    color: ${COLORS.R500};
  }
`;

export const DropdownInputPTag = styled.div`
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  font-size: 14px;
  font-weight: 600;
  position: relative;
  transition: 0.4s;
  color: ${COLORS.N800};
  text-transform: capitalize;
`;

export const DropdownInputErrorMessage = styled.p`
  color: ${COLORS.R500};
  font-size: 10px;
  font-weight: 600;
  line-height: 13px;
  position: absolute;
  bottom: -36px;
  pointer-events: none;
  display: none;
  padding-right: 16px;
  &.show {
    display: flex;
  }
`;
