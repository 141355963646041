import { SyntheticEvent, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { ChevronDown, ChevronUp } from '../../images';
import DropdownList from '../DropdownList/DropdownList';
import {
  DropdownInputContainer,
  DropdownInputErrorMessage,
  DropdownInputLabel,
  DropdownInputPTag
} from './DropdownInput.styles';

export interface IDropdownItem {
  name: string;
  value: string;
  checked: boolean;
}

interface DropdownInputProps {
  title: string;
  values: string[];
  items: Array<{ name: string; value: string }>;
  setNewValue: (newSelection: string[]) => void;
  dropDownMessage?: string;
  errorMessage?: string;
  checkAll?: boolean;
}

const DropdownInput = ({
  title,
  items,
  values,
  setNewValue,
  dropDownMessage,
  errorMessage,
  checkAll
}: DropdownInputProps) => {
  const [dropdownElements, setDropdownElements] = useState<IDropdownItem[]>([]);
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);

  const checkedNames = useMemo(() => dropdownElements.filter((e) => e.checked).map((e) => e.name), [dropdownElements]);
  const placeHolder = useMemo(() => checkedNames.join(', '), [checkedNames]);

  const inputRef = useRef<HTMLDivElement>(null);

  const openField = (e: any) => {
    e.stopPropagation();
    e.preventDefault();
    setIsDropDownOpen(true);
  };

  const closeField = () => {
    setIsDropDownOpen(false);
  };

  const toggleDropdownOpen = useCallback(() => {
    setIsDropDownOpen((prev) => !prev);
  }, []);

  const updateListElements = (newValue: IDropdownItem[]) => {
    const newSelection = newValue.filter((e) => e.checked).map((e) => e.value);

    setNewValue(newSelection);
  };

  // If the list of checkboxes is clicked, stop the event from bubbling up to the parent container
  // This prevents the parent container from closing the dropdown
  const avoidMovingFocus = useCallback((e: SyntheticEvent) => e.stopPropagation(), []);

  useEffect(() => {
    // Use the values to set the checked state of the dropdown elements
    setDropdownElements(items.map((e) => ({ ...e, checked: values.includes(e.value) })));
  }, [values]);

  useEffect(() => {
    const handleClickOutsideInput = (e: any) => {
      if (!inputRef.current?.contains(e.target)) closeField();
    };

    document.addEventListener('mousedown', handleClickOutsideInput);

    return () => {
      document.removeEventListener('mousedown', handleClickOutsideInput);
    };
  }, []);

  return (
    <DropdownInputContainer
      className={`${isDropDownOpen && 'selected'} ${errorMessage && 'error'}`}
      onClick={toggleDropdownOpen}
      onBlur={closeField}
      ref={inputRef}
    >
      <DropdownInputLabel className={`${placeHolder.length > 0 && 'filled'} ${errorMessage && 'error'} `}>
        {title}
      </DropdownInputLabel>
      {placeHolder ? <DropdownInputPTag>{placeHolder}</DropdownInputPTag> : null}

      {!isDropDownOpen ? (
        <ChevronDown width={25} height={25} className="cheveron" onClick={openField} />
      ) : (
        <ChevronUp width={25} height={25} className="cheveron" onClick={closeField} />
      )}

      {isDropDownOpen && (
        <DropdownList
          onClick={avoidMovingFocus}
          className={`dropdown_position ${errorMessage && 'error'}`}
          listElements={dropdownElements}
          onChange={updateListElements}
          message={dropDownMessage}
          selectAllItem={checkAll}
        />
      )}
      <DropdownInputErrorMessage className={`${errorMessage && 'show'}`}>{errorMessage}</DropdownInputErrorMessage>
    </DropdownInputContainer>
  );
};

export default DropdownInput;
