import { useEffect, useRef } from 'react';
import { StyledBackground, StyledModalBox } from './Modal.styles';
import Button, { ButtonProps } from '../Button/Button';

type RequiredButtonProps = Required<Pick<ButtonProps, 'text'>> &
  Pick<ButtonProps, 'buttonSize' | 'buttonType' | 'className' | 'disabled'>;

interface ModalComponentProps {
  title: string;
  textContent?: string;
  children?: JSX.Element | JSX.Element[];
  className?: string;
  open: boolean;
  primaryButton?: RequiredButtonProps;
  onClickPrimaryButton?: () => void;
  secondaryButton?: RequiredButtonProps;
  onClickSecondaryButton?: () => void;
  width: string;
  backdropZIndex?: number;
}

function Modal({
  title,
  textContent,
  children,
  className,
  open,
  secondaryButton,
  onClickSecondaryButton,
  primaryButton,
  onClickPrimaryButton,
  width,
  backdropZIndex = 50
}: ModalComponentProps) {
  const modalRef = useRef<HTMLDivElement | null>(null);
  const handleEscapeKey = (e: KeyboardEvent) => {
    if (e.key === 'Escape' || e.key === 'Esc') {
      if (onClickSecondaryButton) {
        onClickSecondaryButton();
      }
    }
  };

  useEffect(() => {
    if (open) {
      document.addEventListener('keydown', handleEscapeKey);
    }

    return () => {
      document.removeEventListener('keydown', handleEscapeKey);
    };
  }, [open]);
  const outsideClickHandler = (e: any) => {
    if (modalRef.current && !modalRef.current.contains(e.target)) {
      if (onClickSecondaryButton) {
        onClickSecondaryButton();
      }
    }
  };

  return (
    <>
      {open && (
        <StyledBackground onClick={outsideClickHandler} zIndex={backdropZIndex}>
          <StyledModalBox ref={modalRef} innerDivWidth={width} className={className}>
            <h1>{title}</h1>
            {textContent && <p className={`modal-subtitle ${!children ? 'has-no-children' : ''}`}>{textContent}</p>}
            {children && <div className="modal-content">{children}</div>}
            <div className="btn">
              {secondaryButton && (
                <Button
                  text={secondaryButton.text}
                  className={secondaryButton.className}
                  buttonSize={secondaryButton.buttonSize}
                  buttonType={secondaryButton.buttonType}
                  onClick={onClickSecondaryButton}
                />
              )}
              {primaryButton && (
                <Button
                  text={primaryButton.text}
                  disabled={primaryButton.disabled}
                  className={primaryButton.className}
                  buttonSize={primaryButton.buttonSize}
                  buttonType={primaryButton.buttonType}
                  onClick={onClickPrimaryButton}
                />
              )}
            </div>
          </StyledModalBox>
        </StyledBackground>
      )}
    </>
  );
}

export default Modal;
