import React, { useState } from 'react';
import { toInnerRef } from '../../helpers/styled.helpers';
import { ButtonSpinner, HiddenText, StyledButton, StyledButtonRow } from './Button.styles';
import { Popover } from 'react-tiny-popover';

export enum ButtonType {
  primary = 'primary',
  secondary = 'secondary',
  heading = 'heading',
  danger = 'danger',
  success = 'success'
}

export enum ButtonSize {
  none = 'none',
  compact = 'compact',
  default = 'default',
  large = 'large'
}

export interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  buttonType?: ButtonType;
  buttonSize?: ButtonSize;
  text?: string | JSX.Element;
  tooltipText?: string;
  hideTooltipWithClick?: boolean;
  sizeSensitive?: boolean;
  isHighlight?: boolean;
  loadingElement?: JSX.Element;
  toggleLoading?: boolean;
}

const Button = ({
  buttonType,
  buttonSize,
  text,
  tooltipText,
  hideTooltipWithClick = true,
  sizeSensitive,
  className,
  children,
  disabled,
  type = 'submit',
  isHighlight = false,
  onClick,
  loadingElement,
  toggleLoading,
  ...rest
}: ButtonProps): React.ReactElement<ButtonProps> => {
  const ref = (rest as any).ref;
  const buttonRef = toInnerRef(ref || undefined);
  const [isToolTipOpen, setIsToolTipOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const mustShowSpinner = loading || toggleLoading;

  const spinner = loadingElement ? (
    loadingElement
  ) : (
    <ButtonSpinner className="button-spinner">
      <span className="hidden">Loading...</span>
    </ButtonSpinner>
  );

  const onMouseEnter = () => {
    if (window.innerWidth > 1280 && sizeSensitive) {
      setIsToolTipOpen(false);
    } else if (tooltipText == '') {
      setIsToolTipOpen(false);
    } else {
      setIsToolTipOpen(true);
    }
  };

  const handleOnClick = async (e: any) => {
    if (onClick) {
      if (hideTooltipWithClick && isToolTipOpen) {
        setIsToolTipOpen(false);
      }

      setLoading(true);

      try {
        await onClick(e);
      } catch (error) {
        console.error(error);
        //   todo: we might want to add onError callback here
      }

      if (!loading) {
        setLoading(false);
      }
    }
  };

  return (
    <Popover
      padding={10}
      isOpen={isToolTipOpen}
      positions={['bottom', 'top']}
      content={() => <div className={`${tooltipText && 'tool-tip'}`}>{tooltipText && tooltipText}</div>}
    >
      <StyledButton
        data-testid="Button"
        type={type}
        disabled={disabled || mustShowSpinner}
        className={`${className} button-${buttonSize} button-${buttonType} ${disabled && 'disabled'}`}
        {...rest}
        ref={buttonRef}
        onMouseEnter={onMouseEnter}
        onMouseLeave={() => setIsToolTipOpen(false)}
        isHighlight={isHighlight}
        onClick={handleOnClick}
      >
        {mustShowSpinner && spinner}
        <StyledButtonRow className={`${mustShowSpinner ? 'invisible' : ''}`}>
          {children}
          {text && <HiddenText sizeSensitive={sizeSensitive}>{text}</HiddenText>}
        </StyledButtonRow>
      </StyledButton>
    </Popover>
  );
};

export default Button;
