import styled from 'styled-components';
import { COLORS } from '../../styles/colors';

export const StyledBackground = styled.div`
  background-color: var(--modal-backdrop-color);
  position: fixed;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
  height: 100vh;
  width: 100vw;
  display: flex;
  z-index: 40;
  justify-content: center;
  align-items: center;
  padding: 20px;
`;

export const StyledDialogBox = styled.div<{ maxWidth?: string; maxHeight?: string }>`
  background-color: ${COLORS.N000};
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  width: ${(props) => (props.maxWidth ? '100%' : 'fit-content')};
  max-width: ${(props) => props.maxWidth || ''};
  height: ${(props) => (props.maxHeight ? '100%' : 'fit-content')};
  max-height: ${(props) => props.maxHeight || '80%'};
`;

export const StyledHeader = styled.div`
  position: relative;
  top: 0px;
  z-index: 50;
  background-color: ${COLORS.N000};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 24px 10px 24px;
  border-radius: 16px;
  h1 {
    color: ${COLORS.N800};
    font-weight: 700;
    font-size: 26px;
  }
`;

export const StyledSvgContainer = styled.button`
  padding: 10px 16px;
  cursor: pointer;
  border-radius: 11px;
  outline: 1px solid ${COLORS.N300};
  color: ${COLORS.N500};
  display: flex;
  :hover {
    outline: 1px solid ${COLORS.N800};
  }
  :active {
    outline: 2px solid ${COLORS.N800};
  }
  :focus {
    outline: 2px solid ${COLORS.N800};
  }
`;
export const StyledDialogContent = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: scroll;
`;

export const ActionsContainer = styled.div`
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 10px;
  background-color: ${COLORS.N000};
  padding: 10px 30px 30px 30px;
  position: relative;
  bottom: 0px;
  border-radius: 16px;
`;

export const CheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  color: ${COLORS.N800};
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
  margin-right: auto;
  gap: 6px;
`;
