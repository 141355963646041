import { SubRoute } from 'shared/NovaPrimeUI/legacy-ui';
import { lazy } from 'react';
import { Route, Navigate, Routes } from 'react-router-dom';
import routeStrings from 'Routing/routeStrings';
import { SuspenseWithLoader } from 'Routing/SuspenseWrapper';
import { Role } from 'shared/models/User';
import { permissionChecker } from 'shared/utils/permissionChecker';

const Mortgage = lazy(() => import('Sections/Investor/Pages/Mortgage/Mortgage'));
const Admin = lazy(() => import('Sections/Investor/Pages/Admin/Admin'));

const OpenTable = lazy(() => import('Sections/Investor/Pages/Mortgage/Components/OpenTable'));
const CloseTable = lazy(() => import('Sections/Investor/Pages/Mortgage/Components/ClosedTable'));

const { mortgage, admin } = routeStrings.investor;

export const InvestorRoutes = ({ role }: { role: Role[] }) => (
  <SuspenseWithLoader>
    <Routes>
      {permissionChecker(role, [], [Role.Trader, Role.TradeViewer, Role.FullDataAction, Role.FullDataViewer]) && (
        <Route path={mortgage.root} element={<Mortgage />}>
          {permissionChecker(role, [], [Role.Trader, Role.TradeViewer]) && (
            <>
              <Route
                path={mortgage.open}
                element={
                  <SuspenseWithLoader>
                    <OpenTable />
                  </SuspenseWithLoader>
                }
              />
              <Route
                path={`${mortgage.open}/:uuid`}
                element={
                  <SuspenseWithLoader>
                    <OpenTable />
                  </SuspenseWithLoader>
                }
              />
            </>
          )}
          <Route
            path={mortgage.close}
            element={
              <SuspenseWithLoader>
                <CloseTable />
              </SuspenseWithLoader>
            }
          />

          <Route
            path={`${mortgage.close}/:uuid`}
            element={
              <SuspenseWithLoader>
                <CloseTable />
              </SuspenseWithLoader>
            }
          />
          {permissionChecker(role, [], [Role.Trader, Role.TradeViewer]) ? (
            <Route path="*" element={<Navigate to={mortgage.open} />} />
          ) : (
            <Route path="*" element={<Navigate to={mortgage.close} />} />
          )}
        </Route>
      )}
      {permissionChecker(role, [], [Role.UserManagement]) && <Route path={admin} element={<Admin />} />}
      <Route
        path="*"
        element={
          permissionChecker(role, [], [Role.Trader, Role.TradeViewer, Role.FullDataAction, Role.FullDataViewer]) ? (
            <Navigate to={`${mortgage.root}/${mortgage.open}`} />
          ) : (
            <Navigate to={admin} />
          )
        }
      />
    </Routes>
  </SuspenseWithLoader>
);

export const getPageLinks = (role: Role[]) => {
  let ans: any[] = [];

  if (permissionChecker(role, [], [Role.Trader, Role.TradeViewer, Role.FullDataAction, Role.FullDataViewer])) {
    ans = [...ans, { path: routeStrings.investor.mortgage.root, displayText: 'Mortgages' }];
  }
  if (permissionChecker(role, [], [Role.UserManagement])) {
    ans = [...ans, { path: routeStrings.investor.admin, displayText: 'Users' }];
  }
  return ans;
};

export const subRoutes = (role: Role[]): SubRoute[] => {
  let ans: SubRoute[] = [{ title: 'Purchased', path: `${mortgage.close}` }];

  if (permissionChecker(role, [], [Role.Trader, Role.TradeViewer])) {
    ans = [{ title: 'Open', path: `${mortgage.open}` }, ...ans];
  }
  return ans;
};
