import {
  AnyAction,
  createListenerMiddleware,
  isAnyOf,
  ListenerEffectAPI,
  ThunkDispatch,
  type PayloadAction
} from '@reduxjs/toolkit';
import { RootState } from '../../store';
import {
  deleteBulkLoans,
  initBulkGroups,
  removeContractRowsByStatus,
  removeLoanErrors,
  resetState,
  startPollingContract,
  setContractCompleted,
  initBulkImportLoans,
  addBulkLoans,
  updateBulkLoan,
  updateBulkLoansErrors,
  initBulkImportTrades,
  addBulkTrades,
  updateBulkTrade,
  deleteBulkTrades,
  updateBulkTradesErrors,
  removeTradeErrors,
  triggerInitBulkGroups
} from './bulk.slice';
import keyBy from 'lodash/keyBy';
import { BulkStep, DeleteBulkLoansPayload, DeleteBulkTradesPayload } from './types';
import { selectOpenBidMap } from '../bid/bid.selectors';
import { selectConfig } from '../config/config.selectors';
import { selectPayupMap } from '../payup/payup.selectors';
import { selectPricingOption } from '../pricingOption/pricingOption.selectors';
import { groupLoanData } from './bulk-grouping';
import { selectOpenMortgages } from '../mortgage/mortgage.selectors';
import { commitBulkV2 } from './bulk.api.slice';
import { selectBulkContracts, selectBulkLoans, selectBulkTrades } from './bulk.selectors';
import { init } from '../../actions';
import { INITIAL_BULK_CONTRACT_STATUS } from './bulk.constants';
import bulkPollingListener from './bulk.polling';
import { partition } from 'lodash';
import { _validateLoans, _validateTrades } from './bulk.validations';
import { getInvestors } from '../investor/investor.selectors';
import { PricingOption } from 'shared/models/PricingOption';
import { getFirmName } from 'shared/components/Other/FirmName';
import { ServicingRate } from 'shared/models/Bid';
import { findInvestorId, findServicer, findSpecPoolName, getCommitmentStatistics } from './bulk.utils';
import { mixpanelEventNames, mixpanelTrack } from 'shared/mixpanel';
import { selectCurrentUser } from '../user/user.selectors';
import type { IBulkContract } from 'shared/models/Bulk';

const bulkInitListener = createListenerMiddleware();

bulkInitListener.startListening({
  actionCreator: deleteBulkLoans,
  effect: (action: DeleteBulkLoansPayload, api) => {
    const previousState = api.getOriginalState() as RootState;
    const { loanIds } = action.payload;
    const loanIdsSet = new Set(loanIds);
    const loansToRemove = previousState.bulk.importedSourceLoans.filter((loan) => loanIdsSet.has(loan.loanId));

    api.dispatch(removeLoanErrors(loansToRemove));
  }
});

bulkInitListener.startListening({
  actionCreator: deleteBulkTrades,
  effect: (action: DeleteBulkTradesPayload, api) => {
    const previousState = api.getOriginalState() as RootState;
    const { tradeIds } = action.payload;
    const tradeIdsSet = new Set(tradeIds);
    const tradesToRemove = previousState.bulk.importedSourceTrades.filter((trade) => tradeIdsSet.has(trade._id));

    api.dispatch(removeTradeErrors(tradesToRemove));
  }
});

bulkInitListener.startListening({
  actionCreator: triggerInitBulkGroups,
  effect: (_, api) => {
    const state = api.getState() as RootState;

    const openMortgagesMap = selectOpenMortgages(state);
    const bidsMap = selectOpenBidMap(state);
    const payupsMap = selectPayupMap(state);
    const config = selectConfig(state);
    const pricingOption = selectPricingOption(state);
    const payupsByMortgage = selectPayupMap(state);
    const investors = Object.keys(pricingOption);
    const investorsConfig = getInvestors(state);

    // map friendly investor name to slug for finding servicers
    const investorMap: Record<string, PricingOption['investorHint']> = {};
    const investorIds = investors.map((inv) => {
      const friendlyName = getFirmName(inv, config);

      investorMap[friendlyName] = inv;
      return friendlyName;
    });

    const openMortgagesByLoanNumber = keyBy(openMortgagesMap, 'loanNumber');

    const normalizedLoans = state.bulk.importedSourceLoans.map((loan) => {
      const mortgage = openMortgagesByLoanNumber[loan.loanNumber];

      // take user input and find the original investor name, if nothing found, keep user input
      const investorId = findInvestorId(investorIds, loan) ?? loan.investorId;

      // take user input and find the original servicer name, if nothing found, keep user input
      const servicerFirm = findServicer(pricingOption[investorMap[investorId]].servicers, loan) ?? loan.servicerFirm;

      // take user input and find original specPool, if nothing found, keep user input
      const specPool = findSpecPoolName(payupsByMortgage[mortgage.internalId], loan) ?? loan.specPool;

      return {
        ...loan,
        // Number converts .25 to 0.25 and string is required to cast to ServicingRate
        servicerFee: (loan.servicerFee ? `${Number(loan.servicerFee)}` : '') as ServicingRate,
        investorId,
        servicerFirm,
        specPool
      };
    });

    const groups = groupLoanData(
      normalizedLoans,
      openMortgagesByLoanNumber,
      bidsMap,
      payupsMap,
      config,
      investorsConfig
    );

    api.dispatch(initBulkGroups(groups));
  }
});

// TODO: REMOVE
bulkInitListener.startListening({
  actionCreator: removeContractRowsByStatus,
  effect: (action, api) => {
    const state = api.getState() as RootState;

    if (state.bulk.currentStep === BulkStep.FINAL && state.bulk.importedContractData.length === 0) {
      api.dispatch(resetState());
    }
  }
});

/***** Start Bulk Polling *****/
const _getContractsToPoll = (api: ListenerEffectAPI<unknown, ThunkDispatch<unknown, unknown, AnyAction>, unknown>) => {
  const state = api.getState() as RootState;
  const contracts = selectBulkContracts(state).filter(
    (contract) => contract.apiStatus === INITIAL_BULK_CONTRACT_STATUS
  );
  const [notPolling, polling] = partition(contracts, (contract) => !contract.isPolling);
  // Get up to MAX_PARALLEL_POLLING_CONTRACTS contracts to start polling
  const contractToStartPolling = notPolling.slice(0, MAX_PARALLEL_POLLING_CONTRACTS - polling.length);

  return [polling, contractToStartPolling];
};

// Recover Polling on page refresh
bulkPollingListener.startListening({
  actionCreator: init,
  effect: (action, api) => {
    const [polling, contractToStartPolling] = _getContractsToPoll(api);

    [...polling, ...contractToStartPolling].forEach((contract) => {
      api.dispatch(startPollingContract({ id: contract.id }));
    });
  }
});

const MAX_PARALLEL_POLLING_CONTRACTS = 1;

// Start polling the next request(s)
bulkPollingListener.startListening({
  matcher: isAnyOf(commitBulkV2.fulfilled, setContractCompleted),
  effect: (action, api) => {
    const [, contractToStartPolling] = _getContractsToPoll(api);

    contractToStartPolling.forEach((contract) => {
      api.dispatch(startPollingContract({ id: contract.id }));
    });
  }
});

bulkPollingListener.startListening({
  matcher: isAnyOf(setContractCompleted),
  effect: (action: PayloadAction<IBulkContract>, api) => {
    const { internalId: contractId } = action.payload;
    const state = api.getState() as RootState;
    const user = selectCurrentUser(state);
    const contracts = selectBulkContracts(state);
    const completedContract = contracts.find((contract) => contract.id === contractId);

    if (!completedContract) {
      console.error('Completed contract to send analytics for was not found.', { contractId });
      return;
    }
    const commitmentStatistics = getCommitmentStatistics(completedContract);

    mixpanelTrack(mixpanelEventNames.PRIME_COMMIT_COMMITMENT_RESULT, {
      User: user?.auth0Id,
      environment: location.hostname,
      ...commitmentStatistics
    });
  }
});

/**** End Bulk Polling *****/

/*** Start Validate Loans ***/
bulkInitListener.startListening({
  matcher: isAnyOf(initBulkImportLoans, addBulkLoans, updateBulkLoan, deleteBulkLoans),
  effect: (action, api) => {
    const state = api.getState() as RootState;
    const loans = selectBulkLoans(state);

    if (!loans.length) {
      return;
    }

    const config = selectConfig(state);
    const { mortgage: mortgageState } = state;

    const openMortgagesById = keyBy(mortgageState.openMortgages, 'loanNumber');
    const bidsByMortgage = selectOpenBidMap(state);
    const payupsByMortgage = selectPayupMap(state);
    const pricingOption = selectPricingOption(state);

    const errors = _validateLoans(loans, openMortgagesById, bidsByMortgage, payupsByMortgage, pricingOption, config);

    api.dispatch(updateBulkLoansErrors(errors));
  }
});
/*** End Validate Loans ***/

bulkInitListener.startListening({
  matcher: isAnyOf(initBulkImportTrades, addBulkTrades, updateBulkTrade, deleteBulkTrades),
  effect: (action, api) => {
    const state = api.getState() as RootState;
    const trades = selectBulkTrades(state);
    const contracts = selectBulkContracts(state);
    const securitySlotsSet = new Set(
      contracts.flatMap((contract) =>
        contract.loans.flatMap((loan) => loan._securitySlots.flatMap((slot) => slot.name))
      )
    );

    if (!trades.length) {
      return;
    }

    const errors = _validateTrades(trades, securitySlotsSet);

    api.dispatch(updateBulkTradesErrors(errors));
  }
});

export default bulkInitListener;
