import ShowLoginStatus from '../ShowLoginStatus/ShowLoginStatus';
import {
  StyledBurgerMenuButton,
  StyledDropDownMenu,
  StyledHeaderBackground,
  StyledLimit,
  StyledLogo,
  StyledLogoAndLinksGroup,
  StyledNavContainer
} from './Header.styles';
import { LogoSvg, Lines, CloseSvg } from '../../images';
import { useState, useEffect, useRef } from 'react';

interface HeaderProps {
  auth: any;
  handleLogout: any;
  useAppSelector: any;
  pageLinks: { path: string; displayText: string }[];
  NavLink: any;
  navigate: any;
}

const Header = (props: HeaderProps): JSX.Element => {
  const { auth, handleLogout, useAppSelector, pageLinks, NavLink, navigate } = props;

  const [smallScreenMenuOpen, setSmallScreenMenuOpen] = useState(false);
  const smallScreenMenuRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const handleClickOutsideSmallScreenMenu = (e: any) => {
      if (!smallScreenMenuRef.current?.contains(e.target)) setSmallScreenMenuOpen(false);
    };

    document.addEventListener('mousedown', handleClickOutsideSmallScreenMenu);

    return () => {
      document.removeEventListener('mousedown', handleClickOutsideSmallScreenMenu);
    };
  }, []);

  const loginStatus = <ShowLoginStatus auth={auth} useAppSelector={useAppSelector} handleLogout={handleLogout} />;

  const header = (
    <StyledHeaderBackground data-testid="Header">
      <StyledLimit>
        <StyledLogoAndLinksGroup>
          <StyledBurgerMenuButton onClick={() => setSmallScreenMenuOpen(!smallScreenMenuOpen)}>
            {smallScreenMenuOpen ? <CloseSvg data-testid="LinesIcon" /> : <Lines data-testid="LinesIcon" />}
          </StyledBurgerMenuButton>

          <StyledLogo
            tabIndex={0}
            onClick={() => {
              navigate('');
            }}
          >
            <LogoSvg data-testid="LogoIcon" />
          </StyledLogo>

          <StyledNavContainer>
            {pageLinks.map(({ path, displayText }) => (
              <NavLink to={`/${path}`} key={path} className="nav-link cursor-pointer">
                {displayText}
              </NavLink>
            ))}
          </StyledNavContainer>
        </StyledLogoAndLinksGroup>
        {loginStatus}
      </StyledLimit>
    </StyledHeaderBackground>
  );

  const smallMenu = (
    <div ref={smallScreenMenuRef}>
      {smallScreenMenuOpen && (
        <StyledDropDownMenu>
          {pageLinks.map(({ path, displayText }) => (
            <NavLink
              to={`/${path}`}
              key={path}
              className="nav-link w-full cursor-pointer"
              onClick={() => {
                setSmallScreenMenuOpen(false);
              }}
            >
              {displayText}
            </NavLink>
          ))}
        </StyledDropDownMenu>
      )}
    </div>
  );

  return (
    <>
      {header}
      {smallMenu}
    </>
  );
};

export default Header;
