import { ITooltipParams } from 'ag-grid-community';
import { StyledGridToolTipContainer } from './GridTooltip.styles';

const GridTooltip = ({ value }: ITooltipParams): JSX.Element => {
  return (
    <StyledGridToolTipContainer>
      <div>{value}</div>
    </StyledGridToolTipContainer>
  );
};

export default GridTooltip;
