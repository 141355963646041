import { createListenerMiddleware } from '@reduxjs/toolkit';
import {
  addDeactivatedMortgagesToQueue,
  addFailedDeactivate,
  deactivateMortgage,
  removeDeactivatedMortgageFromQueue
} from './mortgage.slice';
import { RootState } from '../../store';
import { addToast } from '../toast/toast.slice';
import { ToastStatus } from 'shared/NovaPrimeUI/legacy-ui';

const mortgageListener = createListenerMiddleware();

/***** Start Deactivate Queue *****/

// Start Queue
mortgageListener.startListening({
  actionCreator: addDeactivatedMortgagesToQueue,
  effect: (action, api) => {
    // state is the state before the action was dispatched
    const prevState = api.getOriginalState() as RootState;
    const state = api.getState() as RootState;
    const { deactivatedMortgagesQueue } = state.mortgage;

    // If there's no queue in the state, then we need to add the mortgages to the queue
    if (prevState.mortgage.deactivatedMortgagesQueue.size === 0 && deactivatedMortgagesQueue.size > 0) {
      const firstMortgageId = deactivatedMortgagesQueue.values().next().value;

      api.dispatch(deactivateMortgage({ internalId: firstMortgageId }));
    }
  }
});

// Remove Mortgage from Queue
mortgageListener.startListening({
  actionCreator: deactivateMortgage.fulfilled,
  effect: (action, api) => {
    const successData = action.payload.data || {};

    const mortgageIdToRemove = successData.id;

    if (!mortgageIdToRemove) {
      // TODO: Add logger
      return;
    }

    api.dispatch(removeDeactivatedMortgageFromQueue(mortgageIdToRemove));
  }
});

// Remove Mortgage from Queue
mortgageListener.startListening({
  actionCreator: deactivateMortgage.rejected,
  effect: (action, api) => {
    const failedData = action.meta.arg || {};

    const mortgageIdToRemove = failedData.internalId;

    if (!mortgageIdToRemove) {
      // TODO: Add logger
      return;
    }

    api.dispatch(addFailedDeactivate(failedData.internalId));
    api.dispatch(removeDeactivatedMortgageFromQueue(mortgageIdToRemove));
  }
});

// End/Continue Queue
mortgageListener.startListening({
  actionCreator: removeDeactivatedMortgageFromQueue,
  effect: (_, api) => {
    const state = api.getState() as RootState;
    const { deactivatedMortgagesQueue, failedToDeactivate } = state.mortgage;

    if (deactivatedMortgagesQueue.size > 0) {
      const firstMortgageId = deactivatedMortgagesQueue.values().next().value;

      api.dispatch(deactivateMortgage({ internalId: firstMortgageId }));
    } else {
      const allRequestsSuccessful = {
        text: 'Loans have been deactivated and have moved to the Sold tab.',
        status: ToastStatus.success
      };
      const someRequestsFailed = {
        text: 'One or more of the selected mortgages was not able to be deactivated. Please review your selection and try again.',
        status: ToastStatus.warning
      };
      const toast = failedToDeactivate.length ? someRequestsFailed : allRequestsSuccessful;

      api.dispatch(addToast(toast));
    }
  }
});

/**** End Deactivate Queue *****/

export default mortgageListener;
