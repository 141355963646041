export function toInnerRef<T>(ref: undefined | React.LegacyRef<T>): undefined | ((instance: T | null) => void) {
  if (!ref) {
    return undefined;
  }
  if (typeof ref === 'string') {
    throw new Error('name refs are not supported.');
  }

  return ref as any;
}
