export const utcToDisplayFormat = (date: string) => {
  const dateObj = new Date(date);

  return `${dateObj.getMonth() + 1}/${dateObj.getDate()}/${dateObj.getFullYear()}`;
};

export const dateComparator = (value: string, param: string, operator: string) => {
  const valueDate = new Date(value);
  const paramDate = new Date(param);

  switch (operator) {
    case 'lessThan':
      return valueDate <= paramDate;
    case 'greaterThan':
      return valueDate >= paramDate;
    default:
      return false;
  }
};

export const localizeDateString = (
  date: Date | undefined | null,
  options: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric'
  }
) => {
  return date?.toLocaleDateString('en-US', options);
};

export const localizeDateStringExpanded = (
  date: Date | undefined | null,
  options: Intl.DateTimeFormatOptions = {
    month: 'long',
    day: 'numeric',
    weekday: 'short'
  }
) => {
  return date?.toLocaleDateString('en-US', options);
};

export const localizeTimeString = (date: Date | undefined | null) => {
  const dateOptions: Intl.DateTimeFormatOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour12: true,
    hour: '2-digit',
    minute: '2-digit'
  };

  return date?.toLocaleDateString('en-US', dateOptions);
};

export const getFullDate = (date: string) => {
  const cleanDate = date ? new Date(date) : null;

  const dateOptions: Intl.DateTimeFormatOptions = { month: 'long', day: '2-digit', year: 'numeric' };
  const fullDate = localizeDateStringExpanded(cleanDate, dateOptions);

  return fullDate;
};

export function isValidTimestamp(timestamp: string): boolean {
  const newTimestamp = new Date(timestamp).getTime();

  return isNumeric(newTimestamp) && !Number.isInteger(Number(timestamp));
}

export function displayFormattedField<t>(data: any, header: any) {
  return isValidTimestamp(data[header.field as keyof t])
    ? utcToDisplayFormat(data[header.field as keyof t])
    : data[header.field as keyof t];
}

const isNumeric = (num: number) => !isNaN(num) && isFinite(num);

export const formatDateToDayMonthYear = (date: Date) => utcToDisplayFormat(date.toISOString());

export const displayFormatToUtc = (date: string): Date => {
  const [month, day, year] = date.split('/').map((string) => parseInt(string));

  return new Date(year, month - 1, day);
};

interface DateRange {
  startDate: string;
  endDate: string;
}

export const createCurrentMonthDateRange = (): DateRange => {
  const todaysDate = new Date();
  const currentYear = todaysDate.getFullYear();
  const currentMonth = todaysDate.getMonth();

  const firstDayOfMonth = new Date(currentYear, currentMonth, 1);
  const lastDayOfMonth = new Date(currentYear, currentMonth + 1, 0);

  return {
    startDate: formatDateToDayMonthYear(firstDayOfMonth),
    endDate: formatDateToDayMonthYear(lastDayOfMonth)
  };
};

const formatSingleDigitDateValue = (value: string) => (value.length === 1 ? `0${value}` : value);

export const reformatDateString = (date: string) => {
  if (!date) return date;
  const [month, day, year] = date.split('/');

  return `${year}-${formatSingleDigitDateValue(month)}-${formatSingleDigitDateValue(day)}`;
};

export function isPastDate(date: string): boolean {
  const now = new Date();
  const incomingDate = new Date(date);

  return now < incomingDate;
}
