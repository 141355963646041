import { useTranslation } from 'react-i18next';
import { StyledAvatar, StyledProfileImage } from './ProfileImage.styles';
import { createClassName, createIntegerHash } from '../../images/shared';

export enum ImageSize {
  Large = 'lg',
  Default = '',
  Small = 'sm'
}

interface ProfileImageProps {
  initials?: string;
  image?: string;
  size?: ImageSize;
  overlap?: boolean;
  index?: number;
  isEditable?: boolean;
  handleClick?: () => void;
  className?: string;
}

const backgroundColors = [
  'Primary',
  'Secondary',
  'Warning',
  'Danger',
  'Info',
  'Red-Light',
  'Red-Dark',
  'Orange-Light',
  'Orange-Dark',
  'Gold-Light',
  'Gold-Dark',
  'Green-Light',
  'Green-Dark',
  'Cyan-Light',
  'Cyan-Dark',
  'Aqua-Light',
  'Aqua-Dark',
  'Persian-Light',
  'Persian-Dark',
  'Purple-Light',
  'Purple-Dark',
  'Magenta-Light',
  'Magenta-Dark',
  'Volcano-Light',
  'Volcano-Dark',
  'Black-Light',
  'Black-Dark'
];

const nameToColor = (initials: string) => {
  const int = createIntegerHash(initials);
  const colorIndex = int % backgroundColors.length;

  const color = backgroundColors[colorIndex].toLocaleLowerCase();

  return color;
};

const Avatar = ({
  overlap = false,
  index,
  profileImageBGColor,
  size = ImageSize.Default,
  image,
  initials,
  className
}: ProfileImageProps & { profileImageBGColor: string; index: number }) => {
  const mergedClasses = createClassName([
    overlap && 'relative ml-[-4px]',
    profileImageBGColor && `profile-image-${profileImageBGColor}`,
    'circular-image uppercase items-center text-center object-cover',
    `justify-center flex image-size-${size}`,
    className && className
  ]);

  return (
    <StyledAvatar>
      {image ? (
        <img src={image} className={`circular-image image-size-${size}`} />
      ) : (
        <div data-testid="ProfileImage" className={mergedClasses} style={{ zIndex: -(index + 1) * 10 }}>
          {initials}
        </div>
      )}
    </StyledAvatar>
  );
};

const ProfileImage = ({
  initials,
  image,
  size,
  overlap = false,
  index = 1,
  isEditable = false,
  handleClick,
  className
}: ProfileImageProps) => {
  const profileImageBGColor = nameToColor(initials || '');
  const { t } = useTranslation();

  const profileImage = (
    <StyledProfileImage
      className={`${size ? `container-${size}` : 'container'} inline-flex items-center justify-center ${className}`}
      onClick={handleClick}
    >
      <Avatar
        overlap={overlap}
        index={index}
        profileImageBGColor={!image ? profileImageBGColor : ''}
        size={size}
        image={image}
        initials={initials}
        isEditable={isEditable}
      />
      {isEditable && (
        <div className={`overlay-base ${size ? `overlay-${size}` : 'overlay'}`}>{t('admin.userDrawer.edit')}</div>
      )}
    </StyledProfileImage>
  );

  return profileImage;
};

export default ProfileImage;
