import { ValidationsAPIDecision, ValidationsAPIStatus, ValidationsAPISummary } from 'shared/models/ValidationsSummary';
import { SaleEligibilityIconState } from './types';

export const getLqaSaleEligibilityIconState = (
  validationRecord: ValidationsAPISummary | null | undefined
): SaleEligibilityIconState => {
  if (validationRecord === undefined) {
    // No record exists because no fetches were made
    // Use a loading state, which UX usages interpret as needing to start a fetch call
    return SaleEligibilityIconState.Loading;
  } else if (validationRecord === null) {
    // A null record is stored if the fetch completes but fails
    // Gray represents error
    return SaleEligibilityIconState.Gray;
  } else if (!validationRecord.lqa) {
    // HD-6859: Fetch succeeded, but no LQA result is available yet
    return SaleEligibilityIconState.Processing;
  } else {
    switch (validationRecord.lqa.status) {
      case ValidationsAPIStatus.PRECHECK_ERROR:
        // Precheck errors mean BE does not submit to the LQA service because missing fields would guarantee a Red status
        return SaleEligibilityIconState.Red;
      case ValidationsAPIStatus.COMPLETE:
        switch (validationRecord.lqa.decision) {
          case ValidationsAPIDecision.RED:
            return SaleEligibilityIconState.Red;
          case ValidationsAPIDecision.YELLOW:
            return SaleEligibilityIconState.Yellow;
          case ValidationsAPIDecision.GREEN:
            return SaleEligibilityIconState.Green;
          case ValidationsAPIDecision.UNKNOWN:
          default:
            // Fallback to an error (represented by a gray icon)
            return SaleEligibilityIconState.Gray;
        }
      // HD-6859: Pending status is not expected to be returned
      case ValidationsAPIStatus.ERROR:
      case ValidationsAPIStatus.PENDING:
      case ValidationsAPIStatus.UNEXPECTED:
      default:
        // Fallback to an error (represented by a gray icon)
        return SaleEligibilityIconState.Gray;
    }
  }
};
