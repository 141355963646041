import styled from 'styled-components';
import { COLORS } from '../../styles/colors';

export const CenterDiv = styled.div`
  display: flex;
  z-index: 9999;
  left: 0;
  right: 0;
  margin: 0 auto;
  justify-content: center;
  position: fixed;
  top: 50px;
`;

export const StyledDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 13px 20px;
  z-index: 100;
  height: 64px;
  background: ${COLORS.N000};
  box-shadow: 0px 2px 40px rgba(0, 0, 0, 0.05);
  border-radius: 16px;
  gap: 4px;
  .toast-content {
    color: ${COLORS.N800};
  }
  &.info-div {
    border: 1px solid ${COLORS.B500};
  }
  &.success-div {
    border: 1px solid ${COLORS.G500};
  }
  &.error-div {
    border: 1px solid ${COLORS.R500};
  }
  &.warning-div {
    border: 1px solid ${COLORS.O700};
  }
`;
