import styled from 'styled-components';
import { COLORS } from '../../styles/colors';

export const StyledInput = styled.input<{ placeholderTextColor?: string }>`
  &::placeholder::after {
    content: '*';
    color: ${COLORS.R500};
  }
  &::placeholder {
    color: ${COLORS.N300};
  }
  font-size: 14px;
  font-weight: 600;
  padding: 15px 20px 15px 16px;
  display: block;
  background: ${COLORS.N000};
  border: 1px solid ${COLORS.N600};
  border-radius: 13px;
`;
export const StyledSpan = styled.span<{ legendTextColor?: string }>`
  color: ${(props) => props.legendTextColor};
  position: absolute;
  top: -7px;
  left: 20px;
  padding: 0px 2px;
  font-size: 10px;
  font-weight: 600;
  transition: top 200ms ease-in, left 200ms ease-in, font-size 200ms ease-in;
`;
