import { IMortgage } from './Mortgage';

// This should be updated whenever the backend enum updates
export enum ValidationsAPIStatus {
  COMPLETE = 'Complete',
  PENDING = 'Pending',
  PRECHECK_ERROR = 'PrecheckError',
  ERROR = 'Error',
  UNEXPECTED = 'Unexpected'
}

// TODO: Verify the mapping for this decision field, when BE is ready
export enum ValidationsAPIDecision {
  RED = 'Red',
  YELLOW = 'Yellow',
  GREEN = 'Green',
  UNKNOWN = 'Unknown'
}

export interface ValidationsAPILQASummary {
  // A mortgageVersion field is returned, but is not used by frontend
  status: ValidationsAPIStatus;
  decision?: ValidationsAPIDecision;
}

export interface ValidationsAPISummary {
  lqa?: ValidationsAPILQASummary;
}

export type ValidationsAPISummaryResponse = {
  data: Record<IMortgage['internalId'], ValidationsAPISummary>;
};
