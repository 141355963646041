import { useQuery } from '@tanstack/react-query';
import { BidRepository } from 'shared/api/repositories/BidRepository/BidRepository';

const _repo = new BidRepository();

export const BIDS_QUERY_KEY = ['bids'];

const useGetBidsQuery = () => {
  return useQuery({
    enabled: false,
    queryKey: BIDS_QUERY_KEY,
    queryFn: async () => _repo.getAll(),
    select: (response) => response.data
  });
};

export default useGetBidsQuery;
