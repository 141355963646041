import React from 'react';
import { StyledIconActive, StyledIconDash, StyledLabel } from './Checkbox.styles';

export interface CheckboxProps
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
    React.AriaAttributes {
  id?: string;
  className?: string;
  activeIconType?: 'dash' | 'check';
}

const Checkbox = ({
  id,
  className,
  checked,
  onClick,
  onChange,
  onFocus,
  onBlur,
  name,
  activeIconType,
  disabled
}: CheckboxProps) => {
  const activeIcon = activeIconType === 'dash' ? <StyledIconDash /> : <StyledIconActive />;

  return (
    <StyledLabel id={id} className={`custom-checkbox ${className}`}>
      <input
        name={name}
        checked={checked}
        className="checkbox"
        type="checkbox"
        onClick={onClick}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        disabled={disabled}
      ></input>
      <span className="checkmark">{checked ? activeIcon : null}</span>
    </StyledLabel>
  );
};

export default Checkbox;
