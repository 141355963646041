export enum COLORS {
  // All colors labled acording to figma design
  N000 = '#FFFFFF',
  N100 = '#F7F8FA',
  N150 = '#F1F3F5',
  N200 = '#ECEEF3',
  N300 = '#CCD1DE',
  N400 = '#989EB5',
  N500 = '#8187A6',
  N600 = '#616D98',
  N700 = '#38447F',
  N800 = '#444F78',
  N900 = '#2D3666',
  G100 = '#ECFAF2',
  G500 = '#3ACC7D',
  G700 = '#04985A',
  G750 = '#037A48',
  R100 = '#FFEEF0',
  R500 = '#FF4C61',
  R550 = '#CC3D4E',
  R700 = '#DB001A',
  B050 = '#F5F7FF',
  B100 = '#EEF2FF',
  B150 = '#E0E8FF',
  B200 = '#D6DFFF',
  B300 = '#BDCBFF',
  B400 = '#8BA5FF',
  B500 = '#5378FD',
  B600 = '#355CE5',
  O100 = '#FFF5EE',
  O200 = '#FFDBC2',
  O400 = '#FCB480',
  O500 = '#FF8D3A',
  O700 = '#EE6400',
  M100 = '#FFEBFB',
  M500 = '#FF0FCA'
}
