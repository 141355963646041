import { IBid, IBidWithNestedBids, IBidWithPayup } from '../../../models/Bid';
import { calculateOutby, flattenBidNoPayup, joinBidWithPayup } from '../../../../state-management/helpers/slice.helper';
import Big from 'big.js';
import { IPayup } from '../../../models/Payup';
import { getBidKey } from '../../../../state-management/slices/bid/bid.utils';
import { TServicingRateByBid } from '../../../../state-management/slices/servicing-rate/servicing-rate.slice';

export function sortByBoolean(a: boolean, b: boolean): number {
  if (a === b) {
    return 0;
  } else if (a) {
    return -1;
  } else {
    return 1;
  }
}

export function compareTwoNumbers(num1: number | string, num2: number | string): 1 | -1 | 0 {
  const num1FinalPrice = new Big(num1);
  const num2FinalPrice = new Big(num2);

  if (num1FinalPrice.gt(num2FinalPrice)) {
    return -1;
  } else if (num1FinalPrice.lt(num2FinalPrice)) {
    return 1;
  } else {
    return 0;
  }
}

export function getBidsWithPayupsSortedByAccepted<IWithIsAccepted extends Pick<IBidWithPayup, 'isAccepted'>>(
  bidsWithPayups: IWithIsAccepted[]
): IWithIsAccepted[] {
  return bidsWithPayups.sort((a, b) => sortByBoolean(a.isAccepted, b.isAccepted));
}

export function getBidsWithPayups(
  bids: IBid[],
  payups: IPayup[],
  servicingRates: TServicingRateByBid
): IBidWithPayup[] {
  const bidsWithPayups: IBidWithPayup[] = [];
  let payupsForBid = [],
    selectedPayup: IPayup | undefined,
    bidKey: string;

  bids.forEach((bid) => {
    // TODO: this is quite expensive On2, we should refactor this to be more efficient
    payupsForBid = payups.filter((payup) => payup.agent === bid.agent);
    selectedPayup = payupsForBid.find((payup) => bid.payup === payup.name);
    bidKey = getBidKey(bid);

    bidsWithPayups.push(flattenBidNoPayup(bid, selectedPayup, servicingRates[bidKey]));
    //check this comparison on the filter
    if (payupsForBid.length) {
      //check that the math is on the same decimal point
      payupsForBid.forEach((payup) =>
        bidsWithPayups.push(joinBidWithPayup(bid, payup, selectedPayup, servicingRates[bidKey]))
      );
    }
  });

  if (bidsWithPayups.length > 1) {
    bidsWithPayups.sort((a, b) => compareTwoNumbers(a.finalPrice, b.finalPrice));
  }

  return bidsWithPayups;
}

export const addOutbyToBids = <IWithFinalPrice extends Pick<IBidWithPayup, 'finalPrice'>>(
  bidsWithPayups: IWithFinalPrice[]
): Array<IWithFinalPrice & Pick<IBidWithPayup, 'outby'>> => {
  if (bidsWithPayups.length <= 1) {
    return bidsWithPayups.map((bidWithPayup) => ({ ...bidWithPayup, outby: null }));
  }

  const firstBid = bidsWithPayups[0];
  const secondBid = bidsWithPayups[1];

  const updatedBids = bidsWithPayups.map((bidWithPayup, i) => {
    if (i === 0) {
      return { ...bidWithPayup, outby: calculateOutby(bidWithPayup.finalPrice, secondBid.finalPrice) };
    } else {
      return { ...bidWithPayup, outby: calculateOutby(bidWithPayup.finalPrice, firstBid.finalPrice) };
    }
  });

  return updatedBids;
};

//This will work once they are sorted
//TODO refactor this, currently it does more than one thing
export function addNestedBids(sortedBidsWithPayups: IBidWithPayup[]): IBidWithNestedBids[] {
  const bids: IBidWithNestedBids[] = [];
  const firmGroups: string[] = [];

  for (const bid of sortedBidsWithPayups) {
    const firmValue = bid.firm;
    const firmValueIndex = firmGroups.findIndex((firm) => firm === firmValue);

    if (firmValueIndex !== -1) {
      bids[firmValueIndex].nestedBids.push(bid); // TODO make a function for this
    } else {
      firmGroups.push(firmValue);
      bids.push({ ...bid, nestedBids: [] }); //TODO make a function for this
    }
  }
  return bids;
}
