import { InfoSvg } from '../../images';
import { StyledDropDownContainer, StyledDropDownRow, StyledMessageBox } from './DropdownList.styles';
import { IDropdownItem } from '../DropdownInput/DropdownInput';
import { useMemo } from 'react';
import Checkbox from '../Checkbox/Checkbox';

interface DropdownListProps {
  className?: string;
  listElements: IDropdownItem[];
  onChange: (newValue: IDropdownItem[]) => void;
  selectAllItem?: boolean;
  onClick?: (e: any) => void;
  message?: string;
}

const DropdownList = ({ className, listElements, onChange, onClick, message, selectAllItem }: DropdownListProps) => {
  // const [unSelectAll, setUnSelectAll] = useState(true);
  const checkedAll = useMemo(() => listElements.every((e) => e.checked), [listElements]);
  const someChecked = useMemo(() => listElements.some((e) => e.checked), [listElements]);

  const toggleCheckAll = (event: any) => {
    event.preventDefault();

    // Creates a new array with the same elements as the original listElements
    // to avoid mutating the original array
    const temp = [...listElements.map(({ value, name }) => ({ name, value, checked: !checkedAll }))];

    onChange(temp);
  };

  const check = (checked: boolean, i: number, event: any) => {
    event.preventDefault();

    // Creates a new array with the same elements as the original listElements
    // to avoid mutating the original array
    const temp = [...listElements.map(({ value, checked, name }) => ({ name, value, checked }))];

    temp[i].checked = !checked;

    onChange(temp);
  };

  return (
    <StyledDropDownContainer className={`${className}`} onClick={onClick}>
      {message && (
        <StyledMessageBox>
          <InfoSvg />
          <p>{message}</p>
        </StyledMessageBox>
      )}
      {selectAllItem && (
        <StyledDropDownRow key="Select All" onClick={toggleCheckAll}>
          <Checkbox
            checked={checkedAll || someChecked}
            activeIconType={checkedAll ? 'check' : someChecked ? 'dash' : undefined}
            onChange={toggleCheckAll}
          />
          <p className="mt-1">Select All</p>
        </StyledDropDownRow>
      )}
      {listElements.map((item, i) => (
        <StyledDropDownRow key={item.value} onClick={(e) => check(item.checked, i, e)}>
          <Checkbox
            value={item.value}
            name={item.name}
            checked={item.checked}
            onChange={(e) => check(item.checked, i, e)}
          />
          <p className="mt-1">{item.name}</p>
        </StyledDropDownRow>
      ))}
    </StyledDropDownContainer>
  );
};

export default DropdownList;
