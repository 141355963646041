import styled from 'styled-components';
import { COLORS } from '../../styles/colors';

export const StyledSearch = styled.div`
  height: fit-content;
  display: flex;
  flex-direction: row;
  padding: 10px 16px;
  border: 1px solid ${COLORS.N300};
  margin: 1px;
  border-radius: 11px;
  color: ${COLORS.N500};
  line-height: 20px;
  overflow: hidden;
  min-width: 80px;
  &:hover {
    border-color: ${COLORS.N800};
  }
  &:focus-within {
    border: 2px solid ${COLORS.N800};
    margin: 0;
  }
  input {
    font-size: 14px;
    padding-left: 10px;
    padding-right: 4px;
    font-weight: 600;
    width: 100%;
    outline: none;
    &::placeholder {
      color: ${COLORS.N500};
    }
    &:focus {
      color: ${COLORS.N800};
    }
  }
`;
