import classNames from 'classnames';
import './index.scss';
import { CheckSvg, ErrorBadgeTriangle } from 'shared/images';
import React from 'react';
import Tooltip from 'shared/components/Tooltip';

export enum NumberBadgeStatus {
  SUCCESS = 'SUCCESS',
  ERROR = 'ERROR',
  INFO = 'INFO'
}

type TNumberBadgeProps = {
  value?: number;
  status?: NumberBadgeStatus;
  tooltip?: string;
  isLoading?: boolean;
  isError?: boolean;
  isRounded?: boolean;
  isPending?: boolean;
  isBubble?: boolean;
};

type TNumberBadgeContainerProps = { children: React.ReactNode } & Pick<
  TNumberBadgeProps,
  'tooltip' | 'status' | 'isRounded' | 'isError' | 'isBubble'
>;

const LoadingDots = () => {
  return (
    <div className="loading-dots">
      <div className="dot dot-flashing"></div>
    </div>
  );
};

const NumberBadgeContainer = ({
  children,
  tooltip,
  isRounded,
  isError,
  status = NumberBadgeStatus.INFO,
  isBubble = true
}: TNumberBadgeContainerProps) => {
  if (!tooltip) {
    return (
      <div
        className={classNames('number-badge', status, { rounded: isRounded, error: isError, 'no-bubble': !isBubble })}
      >
        {children}
      </div>
    );
  }

  return (
    <Tooltip content={tooltip}>
      <div
        className={classNames('number-badge', status, { rounded: isRounded, error: isError, 'no-bubble': !isBubble })}
      >
        {children}
      </div>
    </Tooltip>
  );
};

const NumberBadge = ({
  value = 0,
  status = NumberBadgeStatus.INFO,
  tooltip = '',
  isLoading,
  isError,
  isPending,
  isRounded = false,
  isBubble = true
}: TNumberBadgeProps) => {
  if (isError) {
    return (
      <NumberBadgeContainer isRounded={isRounded} tooltip={tooltip} isError={isError} isBubble={isBubble}>
        <ErrorBadgeTriangle width="12" height="12" />
      </NumberBadgeContainer>
    );
  }

  if (isLoading) {
    return (
      <NumberBadgeContainer isRounded={isRounded} tooltip={tooltip} isBubble={isBubble}>
        <LoadingDots />
      </NumberBadgeContainer>
    );
  }

  if (isPending) {
    return (
      <NumberBadgeContainer
        isRounded={isRounded}
        tooltip={tooltip}
        status={NumberBadgeStatus[NumberBadgeStatus.INFO]} // it's info to use gray colors
        isBubble={isBubble}
      >
        {<CheckSvg width={12} height={12} />}
      </NumberBadgeContainer>
    );
  }

  return (
    <NumberBadgeContainer
      isRounded={isRounded}
      status={NumberBadgeStatus[status]}
      tooltip={tooltip}
      isBubble={isBubble}
    >
      {status === NumberBadgeStatus.SUCCESS ? <CheckSvg width={12} height={12} /> : value}
    </NumberBadgeContainer>
  );
};

export default NumberBadge;
