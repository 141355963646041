import { createContext, useContext, useState } from 'react';
import { IAnalysisCategory, IDocumentTag } from 'shared/models/Analysis-Document';

interface IChecklistModalContext {
  isMissingDocumentModalOpen: boolean;
  setIsMissingDocumentModalOpen: (isOpen: boolean) => void;
  categoryForDocumentSelection: IAnalysisCategory['category'];
  setCategoryForDocumentSelection: (category: IAnalysisCategory['category']) => void;
  setTagsForDocumentSelection: (tags: IDocumentTag['id'][]) => void;
  tagsForDocumentSelection: IDocumentTag['id'][];
  isChecklistSettingsModalOpen: boolean;
  setIsChecklistSettingsModalOpen: (isOpen: boolean) => void;
}

const ChecklistModalContext = createContext<IChecklistModalContext | undefined>(undefined);

interface ChecklistModalContextProviderProps {
  children: React.ReactNode;
}

export const ChecklistModalContextProvider = ({ children }: ChecklistModalContextProviderProps) => {
  const [isMissingDocumentModalOpen, setIsMissingDocumentModalOpen] = useState(false);
  // When importing document into a category folder, the category to be applied can be different than selectedCategory
  const [categoryForDocumentSelection, setCategoryForDocumentSelection] = useState('');
  const [tagsForDocumentSelection, setTagsForDocumentSelection] = useState<IDocumentTag['id'][]>([]);
  const [isChecklistSettingsModalOpen, setIsChecklistSettingsModalOpen] = useState(false);

  // TODO migrate other checklist modals here
  const value = {
    isMissingDocumentModalOpen,
    setIsMissingDocumentModalOpen,
    setCategoryForDocumentSelection,
    categoryForDocumentSelection,
    tagsForDocumentSelection,
    setTagsForDocumentSelection,
    isChecklistSettingsModalOpen,
    setIsChecklistSettingsModalOpen
  };

  return <ChecklistModalContext.Provider value={value}>{children}</ChecklistModalContext.Provider>;
};

export const useChecklistModalContext = () => {
  const context = useContext(ChecklistModalContext);

  if (context === undefined) {
    throw new Error('useChecklistModalContext must be used within a ChecklistModalContextProvider');
  }

  return context;
};
