import type { IBulkGroupLoan, IBulkLoan, IBulkLoansGroup } from 'shared/models/Loan';
import type { IMortgage } from 'shared/models/Mortgage';
import { ValidationsAPISummary } from 'shared/models/ValidationsSummary';

export enum SaleEligibilityIconState {
  // For the secondary sorted enum to stay valid, enum value strings must exactly match their keys
  Red = 'Red',
  // TODO: Change this Orange name to Yellow to better match existing discussed terminology
  Yellow = 'Yellow',
  Green = 'Green',
  // This is used for an error state
  Gray = 'Gray',
  Loading = 'Loading',
  Processing = 'Processing'
}

export enum SaleEligibilityIconStateSorted {
  Red = 0,
  Yellow = 1,
  Green = 2,
  Gray = 3,
  Loading = 4,
  // Processing is sorted last, because a Processing status can take many minutes to change
  Processing = 5
}

export type IBulkLoanWithSaleEligibilityIcon = IBulkLoan & {
  saleEligibilityIconState: SaleEligibilityIconState;
};

export type IBulkGroupLoanWithSaleEligibilityIcon = IBulkGroupLoan & {
  saleEligibilityIconState: SaleEligibilityIconState;
};

export type IBulkLoansGroupWithSaleEligibilityIcon = IBulkLoansGroup & {
  loans: IBulkGroupLoanWithSaleEligibilityIcon[];
};

export type StatusesSlice = {
  // A null validations status for a mortgage occurs when the fetch fails
  validations: Record<IMortgage['internalId'], ValidationsAPISummary | null>;
};
