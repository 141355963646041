export const camelize = (string: string) => {
  if (!string) return '';
  return string.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase());
};

export const createClassName = (stringArray: (string | false | undefined)[]) => {
  return stringArray.filter((x) => x).join(' ');
};

export const createIntegerHash = (input: string): number => {
  let hash = 0;
  const len = input.length;

  for (let i = 0; i < len; i++) {
    hash = (hash << 5) - hash + input.charCodeAt(i);
    hash |= 0; // to 32bit integer
  }
  return hash < 0 ? hash * -1 : hash;
};

export const getUserInitials = (fullName?: string) => {
  if (!fullName || !fullName.length) return '';
  const arr = fullName.split(' ');

  const firstInitial = arr[0][0];

  if (arr.length === 1) return firstInitial;

  const lastIndex = arr.length - 1;

  return firstInitial + arr[lastIndex][0];
};

export const isCamelCase = (str: string) => {
  return /[A-Z]/.test(str);
};

export const includeStartingCharacter = (word: string | string[], character: string) => {
  let value: string | string[];

  if (typeof word === 'string') {
    value = `${character}${word}`;
  } else {
    value = word.map((w) => `${character}${w}`);
  }

  return value;
};

export const splitCamelCase = (input: string) => {
  if (!input) return input;
  return input.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => {
    return str.toUpperCase();
  });
};

export const titleCaseWord = (word: string, isLowerCase = true) => {
  if (!word) return word;
  return word[0].toUpperCase() + (isLowerCase ? word.substring(1).toLowerCase() : word.substring(1));
};

export const camelToSnakeCase = (str: string) => str.replace(/[A-Z]/g, (letter) => `-${letter.toLowerCase()}`);
