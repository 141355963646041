import styled from 'styled-components';
import { COLORS } from '../../styles/colors';

export const StyledSelectorBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  align-items: center;
  padding: 12px 20px;
  gap: 10px;
  position: absolute;
  min-width: 326px;
  width: auto;
  height: 64px;
  left: 50%;
  transform: translate(-50%);
  background: ${COLORS.N800};
  border-radius: 11px;
  white-space: nowrap;
  bottom: 10%;
  p {
    font-size: 14px;
    font-weight: 600;
    line-height: 18px;
    color: #ccd1de;
  }
  svg path {
    stroke: #ccd1de;
  }
`;

export const StyledSelectorBarDiv = styled.div`
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 16px;
  gap: 8px;
  width: auto;
  height: 40px;
  color: #fff;
  border: 1px solid ${COLORS.N500};
  border-radius: 11px;
  p {
    color: ${COLORS.N000};
  }
  &:hover {
    border-color: ${COLORS.N000};
  }
`;
