import styled from 'styled-components';
import { COLORS } from '../../styles/colors';

interface StyledTabsProps {
  marginBottom?: string;
  padding?: string;
  spacing?: string;
}
export const StyledTabs = styled.div<StyledTabsProps>`
  background-color: ${(props: any) => props.theme.tabBackground} !important;
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: ${(props: any) => props.marginBottom || '20px'};
  padding: ${(props: any) => props.padding || '0 30px'};
  gap: ${(props: any) => props.spacing || '16px'};
  color: ${COLORS.N400};
`;

export const TabsCol = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  justify-content: center;
  background-color: white;
  &.active {
    color: ${COLORS.N800};
  }
`;

export const TextWrap = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  &.reverse {
    flex-direction: row-reverse;
  }
`;

interface StyledTabButtonProps {
  padding?: string;
}
export const StyledButton = styled.button<StyledTabButtonProps>`
  display: flex;
  flex-direction: column;
  align-items: center;
  white-space: nowrap;
  padding: ${(props) => props.padding || '0 8px'};
  font-size: 14px;
  font-weight: 600;
`;

export const StyledSpan = styled.span`
  width: 100%;
  height: 2px;
  border-radius: 24px 24px 0 0;
  margin-top: 8px;
  &.active {
    background-color: ${COLORS.N800};
  }
`;
