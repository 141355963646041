import { Role } from 'shared/models/User';

export const permissionChecker = (
  userRoles: Role[],
  notAllowed: Role[] = [],
  needsOneOf: Role[] = [],
  needsAllOf: Role[] = []
) => {
  let isAllowedRoles = false;
  let hasNeededOneOfRoles = false;
  let hasNeededAllOfRoles = false;

  if (needsOneOf.length > 0) {
    hasNeededOneOfRoles = userRoles?.some((ur) => {
      return needsOneOf.indexOf(ur) >= 0;
    });
  } else {
    hasNeededOneOfRoles = true;
  }

  if (needsAllOf.length > 0) {
    hasNeededAllOfRoles = needsAllOf.every((ur) => userRoles.indexOf(ur) >= 0);
  } else {
    hasNeededAllOfRoles = true;
  }

  if (notAllowed.length > 0) {
    const foundRoles = userRoles?.every((ur) => notAllowed.indexOf(ur) >= 0);

    isAllowedRoles = !foundRoles;
  } else {
    isAllowedRoles = true;
  }

  if (isAllowedRoles && hasNeededOneOfRoles && hasNeededAllOfRoles) {
    return true;
  } else {
    return false;
  }
};

// Permissions
export const isDeactivateAllowed = (role: Role[]) => permissionChecker(role, [], [Role.Trader]);

export const isDownloadULDDBtnEnabled = (role: Role[]): boolean => {
  return permissionChecker(role, [], [Role.FullDataAction, Role.FullDataViewer]);
};
export const isDownloadAotPdfsButtonEnabled = (role: Role[]): boolean => {
  return permissionChecker(role, [], [Role.Trader, Role.TradeViewer]);
};

export const isQualityAllowed = (role: Role[]) => {
  return permissionChecker(role, [], [Role.FullDataViewer]);
};

export const isShippingAllowed = (role: Role[]) => {
  return permissionChecker(role, [], [Role.FullDataViewer]);
};

// TODO: Abstract these functions to a permissions by feature class
export const DELIVERY_DATE_ALLOWED_ROLES = [Role.Trader];
export const isDeliveryDateAllowed = (role: Role[]) => {
  return permissionChecker(role, [], DELIVERY_DATE_ALLOWED_ROLES);
};
