import styled from 'styled-components';
import { COLORS } from '../../styles/colors';

export const StyledDropDownContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background: ${COLORS.N000};
  border: 1px solid ${COLORS.N300};
  box-shadow: 0px 2px 40px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  color: ${COLORS.N800};
  overflow: auto;
`;

export const StyledDropDownRow = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
  white-space: nowrap;
  width: 100%;
  padding: 5px 15px;
`;

export const StyledMessageBox = styled.div`
  display: flex;
  width: 100%;
  border-bottom: 1px solid ${COLORS.N300};
  padding: 10px 9px;
  gap: 7px;
  color: ${COLORS.N500};
  background-color: ${COLORS.N150};
  font-size: 12px;
  font-weight: 400;
  align-items: center;
  p {
    color: ${COLORS.N600};
  }
`;
