import Big from 'big.js';
import { ContractStatus, IBulkLoan, IBulkLoansGroup, IBulkTrade } from 'shared/models/Loan';
import { IPayup } from 'shared/models/Payup';
import { Servicers } from 'shared/models/PricingOption';
import { IMortgageFlat } from '../../../shared/models/Mortgage';
import { store } from '../../store';
import { selectOpenMortgages } from '../mortgage/mortgage.selectors';
import { selectPricingOption } from '../pricingOption/pricingOption.selectors';
import { selectConfig } from '../config/config.selectors';
import { selectServicers } from '../servicer/servicer.selectors';
import { selectBidsForMortgage } from '../bid/bid.selectors';
import { _selectPayupsByMortgageId } from '../payup/payup.selectors';
import getBestPrice from './best-price';
import keyBy from 'lodash/keyBy';
import { IBid, ServicingRate } from '../../../shared/models/Bid';
import { PricingOptionState } from '../pricingOption/pricingOption.slice';
import { ServicerState } from '../servicer/servicer.slice';
import { v4 as uuidv4 } from 'uuid';
import { Config } from '../config/config.slice';
import { ValidationsAPISummary } from 'shared/models/ValidationsSummary';
import { IBulkLoansGroupWithSaleEligibilityIcon } from '../statuses/types';
import { getLqaSaleEligibilityIconState } from '../statuses/statuses.utils';

export const getNewBulkLoanId = (): string => uuidv4();
export const getNewTradeId = (): string => uuidv4();

interface IGenerateBulkLoan {
  loanNumber: IBulkLoan['loanNumber'];
  mortgage?: Pick<IMortgageFlat, 'currentInterestRate' | 'internalId'>;
  bidsAndPayupsByMortgageId: TBidsAndPayupsByMortgageId;
  pricingOption: PricingOptionState;
  servicers: ServicerState;
  config: Config;
}

type BulkLoanIdentifiersObject = Record<string, Pick<IBulkLoan, 'loanNumber' | 'specPool'>>;
type TBidsAndPayupsByMortgageId = Record<IBulkLoan['loanNumber'], { payups: IPayup[]; bids: IBid[] }>;

export const getBulkLoanKey = ({ loanNumber, specPool }: Pick<IBulkLoan, 'loanNumber' | 'specPool'>) =>
  `${loanNumber}|${specPool}`;
export const getBulkTradeKey = ({ _id }: Pick<IBulkTrade, '_id'>) => _id;

export function isLoanSpecPoolBase(loan: Pick<IBulkLoan, 'specPool'>): boolean {
  return !loan.specPool.trim();
}

export const createBulkLoanObject = (loans: Pick<IBulkLoan, 'loanNumber' | 'specPool'>[]) => {
  const bulkLoanIdentifiersObject: BulkLoanIdentifiersObject = {};

  for (const loan of loans) {
    const key = getBulkLoanKey(loan);

    bulkLoanIdentifiersObject[key] = loan;
  }

  return bulkLoanIdentifiersObject;
};

export const getLoanIdentifiersFromContractsByStatus = (
  contracts: Pick<IBulkLoansGroup, 'loans' | 'status'>[],
  status: ContractStatus
) => {
  return contracts.reduce((allLoanIdentifiersByStatus: BulkLoanIdentifiersObject, contract) => {
    if (contract.status === status) {
      const loanIdentifiersByStatus = createBulkLoanObject(contract.loans);

      return {
        ...allLoanIdentifiersByStatus,
        ...loanIdentifiersByStatus
      };
    }
    return allLoanIdentifiersByStatus;
  }, {});
};

export const isEqualIgnoreCase = (a: string, b: string) => a.localeCompare(b, 'en', { sensitivity: 'base' }) === 0;

// take user input for servicerFirm and find original
export const findServicer = (servicers: Servicers[], loan: IBulkLoan) => {
  const originalServicer = servicers.find((firm) => isEqualIgnoreCase(firm.servicerName, loan.servicerFirm.trim()));

  return originalServicer?.servicerName;
};

export const findPayupBySpecPool = (loan: IBulkLoan) => (payup: Pick<IPayup, 'name'>) =>
  isEqualIgnoreCase(payup.name, loan.specPool.trim());

// take user input for specPool and find original
export const findSpecPool = <P extends Pick<IPayup, 'name'>>(payups: P[] = [], loan: IBulkLoan) => {
  return (payups || []).find(findPayupBySpecPool(loan));
};

// take user input for specPool and find original
export const findSpecPoolName = (payups: Pick<IPayup, 'name'>[] = [], loan: IBulkLoan) => {
  if (isLoanSpecPoolBase(loan)) {
    return loan.specPool.trim();
  }

  return findSpecPool(payups, loan)?.name;
};

// take user input for investor name and find original
export const findInvestorId = (investorIds: string[], loan: IBulkLoan) => {
  return investorIds.find((inv) => isEqualIgnoreCase(inv, loan.investorId.trim()));
};

export const getMinMaxFromInterestRate = (
  mortgageInterestRate: IMortgageFlat['currentInterestRate']
): Pick<IBulkLoan, 'interestMinRate' | 'interestMaxRate'> => {
  const interestRate = Big(mortgageInterestRate);
  const interestRateMod = interestRate.mod(0.125);
  const isDivisible = interestRateMod.eq(0);

  if (!isDivisible) {
    const closesLowerMinRate = interestRate.minus(interestRateMod);

    return {
      interestMinRate: closesLowerMinRate.toNumber(),
      interestMaxRate: closesLowerMinRate.plus(0.125).toNumber()
    };
  }

  return {
    interestMinRate: interestRate.minus(0.25).toNumber(),
    interestMaxRate: interestRate.plus(0.25).toNumber()
  };
};

const _getDefaults = (loanNumber: string): IBulkLoan => ({
  loanId: getNewBulkLoanId(),
  loanNumber,
  investorId: '',
  interestMinRate: NaN,
  interestMaxRate: NaN,
  specPool: '',
  servicerFirm: '',
  servicerFee: '' as ServicingRate
});

export const generateBulkLoan = ({
  loanNumber,
  mortgage,
  bidsAndPayupsByMortgageId,
  pricingOption,
  servicers,
  config
}: IGenerateBulkLoan): IBulkLoan => {
  if (!mortgage) {
    return _getDefaults(loanNumber);
  }

  const { internalId, currentInterestRate } = mortgage;
  const { bids, payups } = bidsAndPayupsByMortgageId[internalId];

  if (!bids.length) {
    // If there are no bids, we can't calculate the best price
    return { ..._getDefaults(loanNumber), ...getMinMaxFromInterestRate(currentInterestRate) };
  }

  const bestPrice = getBestPrice(bids, payups, pricingOption, config);
  const servicerFirm = servicers[bestPrice.bidKey]?.servicerName || '';
  const { investorId, specPool, servicerFee } = bestPrice;

  return {
    loanId: getNewBulkLoanId(),
    investorId,
    specPool,
    servicerFee,
    ...getMinMaxFromInterestRate(currentInterestRate),
    loanNumber,
    servicerFirm
  };
};

export const generateBulkLoans = (loanNumbers: Array<IBulkLoan['loanNumber']>): IBulkLoan[] => {
  // Using it like this to avoid having `useStore` re-rendering the component
  const state = store.getState();
  const mortgagesByLoanNumber = keyBy(selectOpenMortgages(state), 'loanNumber');
  const pricingOption = selectPricingOption(state);
  const config = selectConfig(state);
  const servicers = selectServicers(state);

  // create map of bids and payups by mortgageId
  const bidsAndPayupsByMortgageId = loanNumbers.reduce((accum, loanNumber) => {
    const mortgage = mortgagesByLoanNumber[loanNumber];

    if (!mortgage) {
      return accum;
    }

    const { internalId } = mortgage;
    const payups = _selectPayupsByMortgageId(state, internalId) || [];
    const bids = selectBidsForMortgage(state, internalId);

    accum[internalId] = {
      payups,
      bids
    };

    return accum;
  }, {} as TBidsAndPayupsByMortgageId);

  return loanNumbers.map((loanNumber) => {
    const mortgage = mortgagesByLoanNumber[loanNumber];

    return generateBulkLoan({ loanNumber, mortgage, bidsAndPayupsByMortgageId, pricingOption, servicers, config });
  });
};

export const isAllEmptyValues = (fields: Omit<IBulkLoan, 'loanNumber' | 'loanId'>) =>
  Object.values(fields).every((value) => value !== 0 && !value);

export const addSaleEligibilityIconToContractLoans = (
  contract: IBulkLoansGroup,
  validations: Record<string, ValidationsAPISummary | null>
): IBulkLoansGroupWithSaleEligibilityIcon => ({
  ...contract,
  loans: contract.loans.map((loanWithoutStatus) => {
    const validationRecord = validations[loanWithoutStatus._internalMortgageId];
    const saleEligibilityIconState = getLqaSaleEligibilityIconState(validationRecord);

    return { ...loanWithoutStatus, saleEligibilityIconState };
  })
});

export const getCommitmentStatistics = (contract: IBulkLoansGroup) => ({
  contractId: contract.id,
  success: contract.status === ContractStatus.SUCCESS,
  loansCount: contract.loans.length,
  investor: contract.investorId
});

export const DEFAULT_SERVICING_FEE: ServicingRate = '0.25';
