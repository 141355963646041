import React from 'react';
import { ErrorMessage, StyledDiv } from './InputTwo.styles';

export interface InputProps
  extends React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,
    React.AriaAttributes {
  id?: string;
  className?: string;
  label: string;
  labelTextColor?: string;
  labelBackground?: string;
  type: string;
  requiredAsterisk?: boolean;
  errorMessage?: string;
}
const Input = ({
  id,
  className,
  checked,
  label,
  labelTextColor,
  type,
  onClick,
  onChange,
  onFocus,
  onBlur,
  value,
  name,
  requiredAsterisk,
  errorMessage
}: InputProps) => {
  return (
    <StyledDiv id={id} labelTextColor={labelTextColor}>
      <input
        className={`${className} ${errorMessage && 'error'}`}
        name={name}
        checked={checked}
        type={type}
        onClick={onClick}
        onChange={onChange}
        onFocus={onFocus}
        onBlur={onBlur}
        value={value}
        data-testid={`input-two-${name}`}
      />
      <label className={`${value && 'filled'} ${errorMessage && 'error'}`} htmlFor={name}>
        {label}
        {requiredAsterisk ? <span className="text-[#FF4C61]">*</span> : ''}
      </label>
      <ErrorMessage>{errorMessage && errorMessage}</ErrorMessage>
    </StyledDiv>
  );
};

export default Input;
