import { useQuery } from '@tanstack/react-query';
import { DeliveryDateRepository } from 'shared/api/repositories/DeliveryDateRepository/DeliveryDateRepository';

const _repo = new DeliveryDateRepository();

export const DELIVERY_DATE_QUERY_KEY = ['deliveryDate'];

const useDeliveryDateQuery = () => {
  return useQuery({
    queryKey: DELIVERY_DATE_QUERY_KEY,
    queryFn: async () => await _repo.get(),
    select: (response) => response.data
  });
};

export default useDeliveryDateQuery;
