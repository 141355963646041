import { useNavigate, useParams } from 'react-router-dom';
import FilterButtons from '../FilterButtons';
import './index.scss';
import { RouteUtils } from 'Sections/Originator/Pages/Quality/RouteUtils';
import { CloseSvg, Group, Settings2 } from 'shared/images';
import Tooltip from 'shared/components/Tooltip';
import { useChecklistModalContext } from '../../ChecklistModalContext';
import { selectOrganizationalTags } from 'state-management/slices/quality-control/quality-control.selectors';
import { useAppDispatch, useAppSelector } from 'state-management/hooks';
import { setOrganizationalTags } from 'state-management/slices/quality-control/quality-control.slice';
import { MouseEvent } from 'react';
import { mixpanelEventNames, mixpanelTrack } from 'shared/mixpanel';
import { useParsedAuth0User } from 'shared/hooks/useParsedAuth0User';

interface ICategoryHeaderContentProps {
  isLoading?: boolean;
}

export enum ChecklistReviewFilter {
  Review = 'Review',
  Complete = 'Complete',
  All = 'All'
}

const buttons = [ChecklistReviewFilter.Review, ChecklistReviewFilter.Complete, ChecklistReviewFilter.All];

const ChecklistTablePanelHeader = ({ isLoading = false }: ICategoryHeaderContentProps) => {
  const dispatch = useAppDispatch();
  const user = useParsedAuth0User();
  const selectedOrganizationalTags = useAppSelector(selectOrganizationalTags);
  const navigate = useNavigate();
  const { setIsChecklistSettingsModalOpen } = useChecklistModalContext();
  const { mortgageId = '', filter = '' } = useParams();
  const onSettingsClick = () => setIsChecklistSettingsModalOpen(true);

  const onFilterChange = (nextStatus: ChecklistReviewFilter) => {
    navigate(RouteUtils.closeChecklistWithParams(mortgageId, nextStatus));
  };

  const orgTagsDisplay = selectedOrganizationalTags.join(', ');
  // clear user selected org tags
  const clearOrganizationalTags = (e: MouseEvent<HTMLButtonElement, globalThis.MouseEvent>) => {
    e.stopPropagation();
    dispatch(setOrganizationalTags([]));

    mixpanelTrack(mixpanelEventNames.CHECKLIST_ORGANIZATIONAL_TAGS_ASSIGNMENT, {
      User: user?.data.userId,
      environment: location.hostname,
      organizationalTags: []
    });
  };

  return (
    <div className="column-header">
      <div className="content-section">
        <div className="filter-buttons-container">
          <FilterButtons<ChecklistReviewFilter>
            onChange={onFilterChange}
            buttons={buttons}
            isLoading={isLoading}
            selected={filter}
          />
          <Tooltip content="Checklist Settings">
            <button className="settings-icon-container" onClick={onSettingsClick}>
              <Settings2 height="16" width="16" />
            </button>
          </Tooltip>
        </div>
      </div>
      {selectedOrganizationalTags.length ? (
        <div className="organizational-tags-container">
          <Tooltip content={orgTagsDisplay} side="right">
            <button className="organizational-tags-pill" onClick={onSettingsClick}>
              <Group height="16" width="16" className="group-icon" />
              Viewing:
              <span className="pill-content">{orgTagsDisplay}</span>
              <button className="pill-clear" onClick={clearOrganizationalTags}>
                <CloseSvg height="16" width="16" />
              </button>
            </button>
          </Tooltip>
        </div>
      ) : null}
    </div>
  );
};

export default ChecklistTablePanelHeader;
