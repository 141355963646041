import styled from 'styled-components';
import { COLORS } from '../../styles/colors';

export const StyledBackground = styled.div<{ zIndex: number }>`
  background-color: var(--modal-backdrop-color);
  position: fixed;
  top: var(--size-collapse);
  bottom: var(--size-collapse);
  left: var(--size-collapse);
  right: var(--size-collapse);
  height: 100vh;
  width: 100vw;
  display: flex;
  z-index: ${(props) => props.zIndex};
`;

export const StyledModalBox = styled.div<{ innerDivWidth?: string }>`
  background-color: ${COLORS.N000};
  border-radius: var(--size-sm);
  display: flex;
  flex-direction: column;
  padding: var(--size-xl);
  margin: auto;
  width: ${(props) => props.innerDivWidth};
  max-height: 100vh;
  overflow: auto;

  h1 {
    color: ${COLORS.N800};
    font-weight: 700;
    font-size: 26px;
    margin-bottom: var(--size-xs);
  }
  .modal-subtitle {
    color: ${COLORS.N500};
    font-weight: 400;
    font-size: var(--size-sm);
    margin-bottom: var(--size-xs);
    &.has-no-children {
      margin-bottom: var(--size-lg);
    }
  }
  .modal-content {
    margin-bottom: var(--size-lg);
  }
  .btn {
    display: flex;
    justify-content: end;
    gap: var(--size-xs);
    margin-top: auto;
  }
  @media (max-width: 479px) {
    margin: auto 20px;
  }
  @media (max-width: 332px) {
    margin: auto 0px;
  }
`;
