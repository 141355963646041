import React from 'react';
import { NavigateFunction } from 'react-router-dom';
import { SubRoute } from '../../index';
import { StyledButton, StyledSpan, StyledTabs, TabsCol, TextWrap } from './Tabs.styles';

interface TabRouteProps {
  subRoutes: SubRoute[];
  navigate: NavigateFunction;
  tabIcons?: (JSX.Element | string)[];
  spacing?: string;
  className?: string;
}

const TabRoutes = React.memo(({ subRoutes, navigate, tabIcons, spacing, className }: TabRouteProps) => {
  function isSelected(route: string) {
    const pathNameSplit = location.pathname.split('/');
    const pathName = pathNameSplit[pathNameSplit.length - 1];

    return pathName === route;
  }

  return (
    <StyledTabs data-testid="TabRoutes" className={className} spacing={spacing}>
      {subRoutes.map(({ title, path }, i) => (
        <TabsCol
          key={`tab-${title}`}
          className={`${isSelected(path) && 'active'}`}
          onClick={() => {
            if (isSelected(path)) return;
            navigate(path);
          }}
        >
          <TextWrap>
            {tabIcons && tabIcons[i] && typeof tabIcons[i] !== 'string' && tabIcons[i]}
            <StyledButton>{title}</StyledButton>
          </TextWrap>

          <StyledSpan className={`${isSelected(path) && 'active'}`} />
        </TabsCol>
      ))}
    </StyledTabs>
  );
});

TabRoutes.displayName = 'TabRoutes';

export default TabRoutes;
