import React, { useEffect, useRef, useState } from 'react';
import { Popover } from 'react-tiny-popover';
import { ButtonProps } from '../Button/Button';
import { CheckboxText, ListOption, StyledDropDown, StyledDropDownContent, SubTxt } from './DropDown.styles';
import { StyledDropDownRow } from '../DropdownList/DropdownList.styles';
import Checkbox from '../Checkbox/Checkbox';

export interface DropDownItemRedirect {
  text: string;
  subTxt?: string;
  location: string;
}

export interface DropDownItemOnClick {
  text: string | JSX.Element;
  subTxt?: string;
  handleOnClick: () => void;
  divider?: boolean;
  className?: string;
  icon?: React.ReactNode;
}

export interface DropDownItemOnClickCheckbox {
  text: string;
  subTxt?: string;
  handleOnClick: () => void;
  checkBox?: boolean;
  checked: boolean;
}

export interface DropDownProps extends ButtonProps {
  buttonContent: JSX.Element;
  dropDownContent: (DropDownItemRedirect | DropDownItemOnClick | DropDownItemOnClickCheckbox)[];
  popoverClass?: string;
  isDropdownOpen?: boolean;
}

const DropDown = ({
  buttonContent,
  dropDownContent,
  popoverClass,
  isDropdownOpen,
  ...rest
}: DropDownProps): React.ReactElement<ButtonProps> => {
  const [isOpen, setOpen] = useState(isDropdownOpen || false);
  const [checkBoxes, setCheckBoxes] = useState<DropDownItemOnClickCheckbox[]>(
    dropDownContent as DropDownItemOnClickCheckbox[]
  );
  const handleToggle = () => setOpen((isOpen) => !isOpen);

  const buttonRef = useRef<HTMLElement | undefined>();

  const check = (e: React.MouseEvent<HTMLDivElement, MouseEvent> | React.ChangeEvent<HTMLInputElement>, i: number) => {
    e.preventDefault();
    const newCheckBoxItems = [...checkBoxes];

    newCheckBoxItems[i].checked = !newCheckBoxItems[i].checked;

    setCheckBoxes(newCheckBoxItems);
  };

  const handleCheckboxClick = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent> | React.ChangeEvent<HTMLInputElement>,
    i: number,
    onClick: () => void
  ) => {
    check(e, i);
    onClick();
  };

  function DropDownContent() {
    const contentOptions = DropDownOptions();

    return <StyledDropDownContent id="dropDownContent">{contentOptions}</StyledDropDownContent>;
  }

  function DropDownOptions() {
    return dropDownContent.map((item, i) => {
      if ((item as DropDownItemRedirect).location == 'noloc') {
        const dropdownItem = item as DropDownItemRedirect;

        return (
          <ListOption className={`${dropdownItem.subTxt && 'header'}`} key={dropdownItem.text}>
            {dropdownItem.text}
            {dropdownItem.subTxt && <SubTxt>{dropdownItem.subTxt}</SubTxt>}
          </ListOption>
        );
      }

      if ((item as DropDownItemOnClickCheckbox).checkBox) {
        const dropdownItem = item as DropDownItemOnClickCheckbox;

        return (
          <StyledDropDownRow
            key={dropdownItem.text}
            onClick={(e) => handleCheckboxClick(e, i, dropdownItem.handleOnClick)}
          >
            <Checkbox
              checked={checkBoxes[i].checked}
              onChange={(e) => handleCheckboxClick(e, i, dropdownItem.handleOnClick)}
            />
            <CheckboxText>{item.text}</CheckboxText>
          </StyledDropDownRow>
        );
      }

      if ((item as DropDownItemOnClick).handleOnClick !== undefined) {
        const dropdownItem = item as DropDownItemOnClick;

        return (
          <>
            {dropdownItem.divider ? <hr /> : ''}
            <ListOption
              onClick={() => dropdownItem.handleOnClick()}
              className={`${dropdownItem.className} ${dropdownItem?.icon && 'icon'}`}
              // todo: fix this
              // eslint-disable-next-line react/no-array-index-key
              key={`dropdown-click-item-${i}`}
              tabIndex={0}
            >
              {dropdownItem?.icon}
              {item.text}
              {item.subTxt && <div>{item.subTxt}</div>}
            </ListOption>
          </>
        );
      }

      return (
        <a href={(item as DropDownItemRedirect).location} tabIndex={0} key={(item as DropDownItemRedirect).text}>
          <ListOption>{item.text}</ListOption>
        </a>
      );
    });
  }

  useEffect(() => {
    setCheckBoxes(dropDownContent as DropDownItemOnClickCheckbox[]);
  }, [dropDownContent]);

  useEffect(() => {
    setOpen(isDropdownOpen || false);
  }, [isDropdownOpen]);

  const buttonRest = { ...rest } as any;

  return (
    <StyledDropDown id={'dropdown-container'}>
      <Popover
        containerClassName={`mt-1 rounded border border-slate-400 shadow-sm z-40 ${popoverClass || ''}`}
        onClickOutside={handleToggle}
        isOpen={isOpen}
        positions={['bottom', 'top']}
        align="end"
        boundaryElement={document.body}
        ref={buttonRef as any}
        content={() => DropDownContent()}
      >
        <div id="dropdown-button" type="button" onClick={handleToggle} {...buttonRest}>
          {buttonContent}
        </div>
      </Popover>
    </StyledDropDown>
  );
};

export default DropDown;
