import { IBaseThunk, thunkReactQueryMiddleware } from '../thunks/base.thunks';
import { AsyncThunk } from '@reduxjs/toolkit';
import { AxiosResponse } from 'axios';
import { IPricingOptionRepository } from 'shared/api/interfaces/IPricingOptionRepository';
import { PricingOption } from '../../shared/models/PricingOption';
import { STALE_TIME_LONG } from '../../shared/api/react-query';

export class PricingOptionThunks implements IBaseThunk<PricingOption> {
  private readonly pricingOptionRepository!: IPricingOptionRepository;

  private baseIdentifier = 'bid';

  constructor(_pricingOptionRepository: IPricingOptionRepository) {
    this.pricingOptionRepository = _pricingOptionRepository;

    if (this.pricingOptionRepository === null) {
      throw new Error('pricingOptionRepository has not been instantiated!');
    }
  }

  add(): AsyncThunk<AxiosResponse<PricingOption, any>, any, Record<string, unknown>> {
    throw new Error('Method not implemented.');
  }

  update(): AsyncThunk<
    AxiosResponse<PricingOption, any>,
    { id: string; item: PricingOption },
    Record<string, unknown>
  > {
    throw new Error('Method not implemented.');
  }

  delete(): AsyncThunk<AxiosResponse<PricingOption, any>, string, Record<string, unknown>> {
    throw new Error('Method not implemented.');
  }

  getAll(): AsyncThunk<AxiosResponse<PricingOption[], any>, void, Record<string, unknown>> {
    throw new Error('Method not implemented.');
  }

  getServicerInfo() {
    const action = `${this.baseIdentifier}/getServicerInfo`;

    return thunkReactQueryMiddleware<PricingOption[], void>(action, () => ({
      staleTime: STALE_TIME_LONG,
      queryKey: [action],
      queryFn: this.pricingOptionRepository.getServicerInfo
    }));
  }
}
