import styled from 'styled-components';
import { COLORS } from '../../styles/colors';

export const StyledDropDown = styled.div``;
export const StyledDropDownButton = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const ListOption = styled.div`
  font-size: 14px;
  padding: 10px 12px;
  display: block;
  cursor: pointer;
  text-align: left;
  width: 100%;
  &.icon {
    display: flex;
    gap: 12px;
  }
  &.header {
    border-bottom: 1px solid ${COLORS.N300};
    pointer-events: none;
    cursor: default;
  }
  &:hover {
    background-color: ${COLORS.N200};
  }
  &:active {
    background-color: ${COLORS.N200};
  }
`;

export const CheckboxText = styled.p`
  font-size: 14px;
  cursor: pointer;
  text-align: left;
  width: 100%;
  margin-top: 8px;
`;

export const ListOptionButton = styled.button`
  font-size: 14px;
  padding: 10px 12px;
  display: block;
  cursor: pointer;
  text-align: left;
  width: 100%;
  &.header {
    border-bottom: 1px solid ${COLORS.N300};
    pointer-events: none;
    cursor: default;
  }
  &:hover {
    background-color: ${COLORS.N200};
  }
  &:active {
    background-color: ${COLORS.N200};
  }
`;

export const SubTxt = styled.p`
  color: ${COLORS.N400};
  font-size: 12px;
`;

export const StyledDropDownContent = styled.div`
  z-index: 60;
  color: ${COLORS.N800};
  background-color: ${COLORS.N000};
  overflow: hidden;
  border-radius: 4px;
  white-space: nowrap;
  min-width: 150px;
  box-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25);
`;
