import { StyledDiv } from './GridTooltipV2.styles';
import { ToastStatus } from '../Toast/Toast';

interface GridTooltipV2Props {
  status: ToastStatus;
  children: JSX.Element | JSX.Element[];
}

const GridTooltipV2 = ({ status, children }: GridTooltipV2Props): JSX.Element => {
  return (
    <StyledDiv className={`${status}-div`}>
      <p>{children}</p>
    </StyledDiv>
  );
};

export default GridTooltipV2;
