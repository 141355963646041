import React, { useEffect, useRef } from 'react';
import { SearchSvg, CloseSvg } from '../../images';
import { StyledSearch } from './Search.styles';

export interface SearchProps {
  searchValue: string;
  handleSearchValue: (value: string) => void;
  onUnload?: () => void;
  placeholder?: string;
  isClearEnabled?: boolean;
}

export const Search = ({
  searchValue,
  handleSearchValue,
  onUnload,
  placeholder = 'Search',
  isClearEnabled = true
}: SearchProps) => {
  function handleUnload() {
    if (onUnload) {
      onUnload();
    } else {
      handleSearchValue('');
    }
  }

  useEffect(() => {
    return handleUnload();
  }, []);

  window.onbeforeunload = () => {
    return handleUnload();
  };

  const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleSearchValue(e.target.value);
  };

  const handleCancelSearch = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    handleSearchValue('');
  };

  const inputRef = useRef<HTMLInputElement>(null);

  const handleClick = () => {
    inputRef?.current?.focus();
  };

  return (
    <StyledSearch className="search" onClick={handleClick}>
      <SearchSvg />
      <input
        className="search-input"
        ref={inputRef}
        type="input"
        value={searchValue}
        placeholder={placeholder}
        onChange={handleOnChange}
      />

      {isClearEnabled ? (
        <button className={`${searchValue.length > 0 ? 'visible' : 'invisible'} `} onClick={handleCancelSearch}>
          <CloseSvg />
        </button>
      ) : null}
    </StyledSearch>
  );
};
