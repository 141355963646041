import { CenterDiv, StyledDiv } from './Toast.styles';
import { SuccessSvg, FailureSvg, CloseSvg, WarningSvg } from '../../images';

interface ToastProps {
  toastStatus: ToastStatus;
  textDescription: string | JSX.Element;
  onClose: () => void;
}

export enum ToastStatus {
  info = 'info',
  success = 'success',
  warning = 'warning',
  error = 'error'
}

const Toast = ({ toastStatus, textDescription, onClose }: ToastProps): JSX.Element => {
  return (
    <CenterDiv>
      <StyledDiv className={`${toastStatus}-div`}>
        {toastStatus === ToastStatus.info && <SuccessSvg width={38} height={38} />}
        {toastStatus === ToastStatus.success && <SuccessSvg width={38} height={38} />}
        {toastStatus === ToastStatus.warning && <WarningSvg width={38} height={38} />}
        {toastStatus === ToastStatus.error && <FailureSvg width={38} height={38} />}
        <div className="toast-content">{textDescription}</div>
        <CloseSvg width={20} height={20} className="ml-3 cursor-pointer" onClick={onClose} />
      </StyledDiv>
    </CenterDiv>
  );
};

export default Toast;
